import { useCallback, useEffect, useMemo, useState } from 'react';
import { addDays, startOfWeek } from 'date-fns';
import { useTranslation } from 'react-i18next';
import type { Id } from 'react-calendar-timeline';
import usePropertiesState from '@context/propertiesContext';
import useAppContext, { Integration } from '@context/appContext';

import { DateRangeNavigation, AddSlotButton, SearchByRoom, SlotFilter, SlotLegend } from './Header';
import { RoomSlotSidebar } from './RoomSlotSidebar';
import { RoomRackTable } from './RoomRackTable';
import Loader from '@molecules/Loader';

import useGetRoomRackData from '@utils/hooks/useGetRoomRackData';
import { formatSlotsData, slotOptions } from './utils';

import { DateRangeType, SlotItem } from './RoomRackTable/types';
import { SlotType } from './types';

export const MOBILE_DEFAULT_DATE_RANGE_LENGTH = 6;
export const DESKTOP_DEFAULT_DATE_RANGE_LENGTH = 13;
export const APALEO_DEFAULT_DATE_RANGE_LENGTH = 19;

export const DEFAULT_INITIAL_DATE = startOfWeek(new Date(), { weekStartsOn: 1 });

export default function RoomRack() {
  const [isSlotDialogOpen, setIsSlotDialogOpen] = useState<boolean>(false);
  const [slotSelected, setSlotSelected] = useState<SlotItem | null>(null);

  const [dateRangeLength, setDateRangeLength] = useState<number>(DESKTOP_DEFAULT_DATE_RANGE_LENGTH);
  const [dateRange, setDateRange] = useState<DateRangeType>({
    start: DEFAULT_INITIAL_DATE,
    end: addDays(DEFAULT_INITIAL_DATE, DESKTOP_DEFAULT_DATE_RANGE_LENGTH),
  });

  const [filteredByRoomId, setFilteredByRoomId] = useState<string | undefined>(undefined);
  const [filteredSlotByType, setFilteredSlotByType] = useState<SlotType[]>([]);

  const { t } = useTranslation();
  const { selectedProperty } = usePropertiesState();
  const { id: propertyId, externalId, timeZone, enableRoomRack } = selectedProperty;

  const { integration, isFullScreen } = useAppContext();

  const { roomRack, isFetching } = useGetRoomRackData({
    propertyId,
    timeZone,
    fromDate: dateRange.start.toISOString(),
    toDate: dateRange.end.toISOString(),
  });

  useEffect(() => {
    setDateRange({
      start: DEFAULT_INITIAL_DATE,
      end: addDays(DEFAULT_INITIAL_DATE, dateRangeLength),
    });
  }, [dateRangeLength]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setDateRangeLength(MOBILE_DEFAULT_DATE_RANGE_LENGTH);
      } else if (isFullScreen) {
        setDateRangeLength(APALEO_DEFAULT_DATE_RANGE_LENGTH);
      } else {
        setDateRangeLength(DESKTOP_DEFAULT_DATE_RANGE_LENGTH);
      }
    };

    handleMediaQueryChange(mediaQuery as unknown as MediaQueryListEvent);
    mediaQuery.addListener(handleMediaQueryChange);
    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, [isFullScreen]);

  const roomsData = useMemo(() => {
    if (!roomRack) return null;
    return roomRack.map(({ unitId, unitNumber }) => {
      return { id: unitId, title: `${unitNumber}` };
    });
  }, [roomRack]);

  const slotData = useMemo(() => (roomRack && formatSlotsData(roomRack)) ?? [], [roomRack]);

  const roomsFilteredData = useMemo(() => {
    if (!roomsData) return null;
    const selectedRoom = filteredByRoomId && roomsData.find((room) => room.id === filteredByRoomId);
    if (selectedRoom) {
      return [{ id: selectedRoom.id, title: selectedRoom.title }];
    } else {
      return roomsData;
    }
  }, [roomsData, filteredByRoomId]);

  const slotFilteredData = useMemo(() => {
    if (filteredSlotByType.length === 0) return slotData;
    return slotData.filter(({ type }) => filteredSlotByType.includes(type));
  }, [slotData, filteredSlotByType]);

  const handleNewSlot = () => {
    setSlotSelected(null);
    setIsSlotDialogOpen(true);
  };

  const handleRoomSearch = (roomSearched: string) => {
    if (!roomSearched || roomSearched === '') {
      setFilteredByRoomId(undefined);
    } else if (roomSearched === filteredByRoomId) {
      setFilteredByRoomId('');
    } else {
      setFilteredByRoomId(roomSearched);
    }
  };

  const handleSlotFiltered = (newSelectedSlots: SlotType[]) => {
    setFilteredSlotByType(newSelectedSlots);
  };

  const handleSlotClick = (itemId: Id) => {
    const item = slotData.find((slot) => slot.id === itemId);

    if (!item) return;
    if (item.type === 'RESERVATION') {
      setIsSlotDialogOpen(false);
      setSlotSelected(null);
      window.open(`https://app.apaleo.com/${externalId}/reservations/${item?.externalId}/actions`, '_blank');
    } else {
      setIsSlotDialogOpen(true);
      setSlotSelected(item);
    }
  };

  const RoomRackTableSection = useCallback(() => {
    if (!roomsFilteredData || !slotFilteredData) return null;

    return (
      <div className="relative h-auto overflow-auto">
        {isFetching && (
          <div className="absolute z-50 flex justify-center items-center w-full h-full">
            <Loader />
          </div>
        )}
        <RoomRackTable
          onSlotClick={handleSlotClick}
          dateRange={dateRange}
          slotsData={slotFilteredData}
          roomsData={roomsFilteredData}
        />
      </div>
    );
  }, [roomsFilteredData, handleSlotClick, slotFilteredData, dateRange]);

  if (integration === Integration.APALEO && !enableRoomRack) {
    return (
      <div className="flex justify-center items-center h-full font-sans">
        <div className="text-th-brown text-base">The Room Rack has not been onboarded yet for this property</div>
      </div>
    );
  }

  if (isFetching && !roomsFilteredData)
    return (
      <div className="flex justify-center items-center mt-20">
        <Loader />
      </div>
    );

  if (!roomsFilteredData || !slotFilteredData || !roomsData) return <div>Error data</div>;

  return (
    <div className="flex flex-col h-full">
      <div className="px-2 sm:px-0 flex flex-col sm:flex-row justify-between gap-y-2 sm:gap-4 mb-4 sm:mb-6 mt-2 font-sans text-th-brown text-sm leading-[18px] font-semibold">
        <div className="flex flex-wrap items-center gap-2">
          <AddSlotButton handleNewSlot={handleNewSlot} />
          <SearchByRoom filteredByRoomId={filteredByRoomId} roomsData={roomsData} handleRoomSearch={handleRoomSearch} />
          <SlotFilter
            placeholder={t('slotType')}
            options={slotOptions}
            selectedOptions={filteredSlotByType}
            onChange={handleSlotFiltered}
          />
          <div className="sm:hidden flex-shrink-0">
            <DateRangeNavigation dateRange={dateRange} setDateRange={setDateRange} dateRangeLength={dateRangeLength} />
          </div>
        </div>
        <div className="hidden sm:block flex-shrink-0">
          <DateRangeNavigation dateRange={dateRange} setDateRange={setDateRange} dateRangeLength={dateRangeLength} />
        </div>
      </div>
      <SlotLegend />

      <RoomRackTableSection />

      {isSlotDialogOpen && (
        <RoomSlotSidebar
          slotSelected={slotSelected}
          rooms={roomsData}
          dateRange={dateRange}
          onClose={() => {
            setIsSlotDialogOpen(false);
            setSlotSelected(null);
          }}
        />
      )}
    </div>
  );
}
