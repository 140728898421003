import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Quạt phòng tắm ồn ào',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Đèn bị lỗi',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Chậu rửa bị chặn',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Vòi hoa sen bị chặn',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Bộ lọc phòng tắm',
  [StandardizedDamageReport.SINK]: 'Bồn rửa',
  [StandardizedDamageReport.TOILET]: 'Phòng vệ sinh',
  [StandardizedDamageReport.SHOWER]: 'Vòi hoa sen',
  [StandardizedDamageReport.STOVE]: 'Bếp',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Tủ lạnh / Minibar',
  [StandardizedDamageReport.DOOR]: 'Cửa',
  [StandardizedDamageReport.WINDOW]: 'Cửa sổ',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Bóng đèn',
  [StandardizedDamageReport.CHAIRS]: 'Nhiều cái ghế',
  [StandardizedDamageReport.HEATING]: 'Sưởi',
  [StandardizedDamageReport.TV]: 'truyền hình',
  [StandardizedDamageReport.TABLES]: 'Những cái bàn',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Nhóm Dọn Dẹp',
  [Team.MAINTENANCE]: 'Nhóm Bảo Trì',
  [Team.OPERATIONS]: 'Nhóm Điều Hành',
  [Team.ICT]: 'ICT',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Phòng bếp',
  [DamageReportArea.BED]: 'Giường',
  [DamageReportArea.BATHROOM]: 'Phòng tắm',
  [DamageReportArea.SEATING_AREA]: 'Khu vực chỗ ngồi',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Tường / Trần / Sàn',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Dọn dẹp',
  [DamageReportActionRequired.REPLACE]: 'Thay thế',
  [DamageReportActionRequired.REPAIR]: 'Sửa chữa',
  [DamageReportActionRequired.CHECK]: 'Đánh dấu',
  [DamageReportActionRequired.OTHER]: 'Khác',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Tiêu chuẩn',
  [TaskPriority.HIGH]: 'Cao',
};

const popupNotifications = {
  taskDeletionNotification: 'Nhiệm vụ đã được xóa thành công',
  lostAndFoundDeletionNotification: 'Mục tìm đồ thất lạc đã được xóa thành công',
  damageReportDeletionNotification: 'Báo cáo thiệt hại đã được xóa thành công',
  taskCreationNotification: 'Nhiệm vụ đã được tạo thành công',
  lostAndFoundCreationNotification: 'Mục tìm đồ thất lạc đã được tạo thành công',
  damageReportCreationNotification: 'Báo cáo thiệt hại đã được tạo thành công',
  taskCompletionNotification: 'Nhiệm vụ đã được hoàn thành thành công',
  lostAndFoundCompletionNotification: 'Mục tìm đồ thất lạc đã được giải quyết thành công',
  damageReportCompletionNotification: 'Báo cáo thiệt hại đã được giải quyết thành công',
  taskUpdateNotification: 'Nhiệm vụ đã được cập nhật thành công',
  lostAndFoundUpdateNotification: 'Mục tìm đồ thất lạc đã được cập nhật thành công',
  damageReportUpdateNotification: 'Báo cáo thiệt hại đã được cập nhật thành công',
  pleaseSelectRoomsNotification: 'Vui lòng chọn phòng',
  noShowReportNotification: 'No Show đã báo cáo thành công cho phòng {{number}}',
  checkInReportNotification: 'Check in đã báo cáo thành công cho phòng {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Báo cáo thiệt hại được lập lịch thành công cho {{date}}',
  hskDelayNotification: 'Chậm HSK thành công cho phòng {{number}}. Thời gian đăng ký được cập nhật thành {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Thêm bộ lọc',
  resetAllFilters: 'Đặt lại tất cả bộ lọc',
  reset: 'Đặt lại',
  cleaned: 'Đã Dọn',
  allRoomsCleaned: `Tất cả các phòng đã được dọn dẹp`,
  dirty: 'Bẩn',
  readyToClean: 'Sẵn Sàng Để Dọn',
  stayoverReadyToClean: 'Ngủ lại - Sẵn Sàng Để Dọn',
  occupiedCleaningToday: 'Bận - Hôm Nay Đang Dọn',
  readyToInspect: 'Sẵn Sàng Kiểm Tra',
  stayoverInspection: 'Kiểm Tra Lưu Trú',
  vacant: 'Bỏ trống',
  occupied: 'Bận',
  clean: 'Dọn dẹp',
  daily: 'Hằng ngày',
  weekly: 'Hằng tuần',
  biweekly: 'Hai Tuần Một Lần',
  monthly: 'Hằng tháng',
  none: 'Không',
  tasks: 'Nhiệm vụ',
  notifications: 'Thông báo',
  more: 'Nhiều hơn',
  select: 'Chọn',
  property: 'Tài sản',
  result: 'Kết quả',
  results: 'Kết quả',
  properties: 'Tài sản',
  rooms: 'Phòng',
  team: 'Nhóm',
  dueDate: 'Ngày đáo hạn',
  date: 'Ngày',
  close: 'Đóng',
  search: 'Tìm kiếm',
  login: 'Đăng nhập',
  loading: 'Đang tải...',
  anErrorHasOccurred: 'Có lỗi xảy ra',
  chooseDate: 'Chọn Ngày',
  selectDate: 'Chọn ngày',
  selectADate: 'Chọn một ngày',
  markAsClean: 'Đánh dấu là đã sạch',
  markAsReadyToInspect: 'Đánh dấu là sẵn sàng để kiểm tra',
  viewTasks: 'Xem Nhiệm Vụ',
  eci: 'ECI', // 'Check-in sớm',
  lco: 'LCO', // 'Check-out muộn',
  snacks: 'SNACKS',
  roomLog: `Bảng ghi chú phòng`,
  taskLog: `Nhật ký công việc`,
  seeDetails: 'Xem Chi Tiết',
  assignedTasks: 'Giao Nhiệm Vụ',
  delete: 'Xóa',
  deleteTask: 'Xóa Nhiệm Vụ',
  deleteTaskConfirmationMessage: 'Bạn có chắc chắn muốn xóa nhiệm vụ này không?',
  deleteTaskRepeatsConfirmationMessage:
    'Đây là nhiệm vụ lặp lại. Bạn có thể xóa nhiệm vụ này hoặc các nhiệm vụ lặp lại tương tự trong tương lai',
  deleteOnlyThisTask: 'Chỉ xóa nhiệm vụ này',
  deleteRepeatingTask: 'Xóa nhiệm vụ lặp lại',
  yes: 'Có',
  no: 'Không',
  pleaseWaitAFewSeconds: 'Vui lòng đợi vài giây',
  deleting: 'Đang xóa',
  lostAndFound: 'Phòng Tìm Đồ',
  current: 'Hiện tại',
  archive: 'Lưu trữ',
  edit: 'Chỉnh sửa',
  markAsResolved: 'Đánh dấu là đã giải quyết',
  markAsUnresolved: 'Đánh dấu là chưa giải quyết',
  add: 'Thêm',
  area: 'Khu vực',
  addTask: 'Thêm nhiệm vụ',
  editTask: 'Chỉnh sửa nhiệm vụ',
  seeTaskDetails: 'Xem công việc',
  savePicturesAndStartCleaning: 'Lưu và bắt đầu dọn dẹp',
  savePictures: 'Lưu hình ảnh',
  takePhoto: 'Chụp ảnh',
  addArea: 'Thêm khu vực',
  addAreaDescription: 'Thêm khu vực của khách sạn cho nhiệm vụ này',
  selectProperty: 'Chọn tài sản',
  selectRooms: 'Chọn phòng',
  selectTeam: 'Chọn nhóm',
  selectDueDate: 'Chọn ngày',
  hskTeam: 'Nhóm Dọn Dẹp',
  save: 'Lưu',
  actionIsRequired: `Hành động cần thiết. Xóa tiêu đề và chọn một hành động từ danh sách.`,
  noActionSelected: `Không hành động nào được chọn`,
  changeSearchAction: `Thử thay đổi từ khóa tìm kiếm của bạn`,
  noActionFound: `Không tìm thấy hành động`,
  selectActionHelp: `Không tìm thấy hành động nào? Vui lòng liên hệ với nhóm Vận hành để họ có thể yêu cầu nó.`,
  writeToSelectAction: 'Viết để chọn một hành động',
  writeTask: 'Viết nhiệm vụ',
  repetition: 'Sự lặp lại',
  noRepetition: 'Không lặp lại',
  repetitionTime: 'Thời gian lặp lại',
  doesNotRepeat: 'Không lặp lại',
  day: 'Ngày',
  week: 'Tuần',
  month: 'Tháng',
  repeatOn: 'Lặp lại vào',
  repeatsEvery: 'Lặp lại mỗi',
  description: 'Miêu tả',
  title: 'Tiêu đề',
  addTitle: 'Thêm tiêu đề',
  titleIsRequired: 'Tiêu đề là bắt buộc',
  titleMaxLength: 'Tiêu đề nên ít hơn {{maxLength}} ký tự',
  teamIsRequired: 'Nhóm là bắt buộc',
  openTasks: 'Mở nhiệm vụ',
  arrival: 'Đến',
  departure: 'Khởi hành',
  checkIn: 'Đăng ký',
  times: 'Lần',
  guests: 'Khách',
  name: 'Tên',
  additionalInfo: 'Thông Tin Bổ Sung',
  addLostAndFound: 'Thêm Phòng Tìm Đồ',
  addDamageReport: 'Thêm báo cáo thiệt hại',
  unitIsRequired: 'Đơn vị là bắt buộc',
  addItem: 'Thêm sản phẩm',
  editItem: 'Chỉnh sửa sản phẩm',
  startCleaning: 'Bắt đầu dọn dẹp',
  endCleaning: 'Dọn dẹp xong',
  endCleaningMessage: 'Còn 1 nhiệm vụ đang mở. Bạn có chắc chắn đã hoàn thành việc dọn dẹp?',
  endCleaningMessage_plural: 'Còn {{count}} nhiệm vụ đang mở. Bạn có chắc chắn đã hoàn thành việc dọn dẹp?',
  cancelAndViewTasks: 'Hủy và xem nhiệm vụ',
  ignoreAndMarkAsReadyToInspect: 'Bỏ qua và đánh dấu là sẵn sàng để kiểm tra',
  ignoreAndMarkAsCleaned: 'Bỏ qua và đánh dấu là đã sạch',
  damageReports: 'Báo cáo thiệt hại',
  confirm: 'Xác nhận',
  selectRoomOrArea: 'Chọn phòng/khu vực hoặc ',
  createNewOne: 'tạo mới',
  today: 'Hôm nay',
  tomorrow: 'Ngày mai',
  noTasksMessage: 'Không có nhiệm vụ nào được thêm vào',
  cleaningStatus: 'Đang dọn',
  logOut: 'Đăng xuất',
  language: 'Ngôn ngữ',
  selectLanguage: 'Chọn Ngôn Ngữ',
  isStandardized: 'Được tiêu chuẩn hóa?',
  selectStandardizedTask: 'Chọn nhiệm vụ được tiêu chuẩn hóa',
  overdue: 'Quá hạn',
  arrivals: 'Đến',
  memberArrivals: 'Member arrivals',
  departures: 'Khởi hành',
  stayovers: 'Dọn dẹp giữa giờ',
  occupancy: 'Công suất OTB',
  midstayCleaning: 'Dọn dẹp giữa kỳ nghỉ',
  roomsToSell: 'Phòng để bán',
  editImages: 'Chỉnh sửa hình ảnh',
  toClean: 'làm sạch',
  toInspect: 'để kiểm tra',
  noRoomsForCleaningToday: 'Không có phòng để dọn dẹp hôm nay',
  noShow: 'Không có chương trình (No show)',
  noShowReportConfirmationMessage: 'Vui lòng xác nhận rằng số phòng {{number}} là No show.',
  noShowReportQuestion: 'Khách đã nhận phòng hôm qua chưa?',
  checkInReportConfirmationMessage: 'Vui lòng xác nhận rằng khách đã đăng ký vào phòng số {{number}}.',
  noResultsSearchMessage: 'Không có kết quả nào phù hợp với tiêu chí tìm kiếm của bạn',
  cancel: 'Hủy bỏ',
  selectCleaner: 'Chọn chất tẩy rửa',
  selectAll: 'Chọn tất cả',
  floor: 'Sàn nhà',
  notAssigned: 'Không được chỉ định',
  assignXRooms: 'Chỉ định {{total}} phòng',
  assignRooms: 'Chỉ định phòng',
  approvedLCOs: 'LCO được phê duyệt',
  cleaner: 'Sạch hơn',
  roomsCleanForToday: 'Tất cả các phòng đều sạch sẽ cho ngày hôm nay! 🎉🎉',
  viewDamageReports: 'Xem báo cáo thiệt hại',
  noTasks: 'Không có nhiệm vụ',
  noDamageReports: 'Không có báo cáo thiệt hại',
  dailyCleaningReport: 'Báo cáo vệ sinh hàng ngày',
  stayover: 'Ở trên',
  roomMoveFrom: 'Chuyển phòng từ {{value}}',
  roomMoveTo: 'Chuyển phòng đến {{value}}',
  unassignAll: 'Bỏ chỉ định tất cả',
  unassignRoomsConfirmationMessage: 'Bạn có chắc chắn muốn hủy chỉ định {{total}} phòng không?',
  unassignRoomsTitle: 'Bỏ chỉ định phòng',
  noRoomAssigned: 'Không có phòng nào được chỉ định',
  action: 'Hành động',
  hideActions: 'Ẩn các hành động',
  showActions: 'Hiển thị hành động',
  actionsSelected: '{{total}} hành động được chọn',
  disableActionTitleModal: 'Đặt lại bộ lọc {{name}}',
  disableActionDescriptionModal: 'Để sử dụng bộ lọc {{currentFilter}}, vui lòng đặt lại bộ lọc {{resetFilter}}.',
  actionRequired: 'Cần hành động',
  priority: 'Sự ưu tiên',
  lcoUntil: 'LCO cho đến khi',
  created: 'Tạo',
  startNow: 'Bắt đầu bây giờ',
  scheduleTime: 'Thời gian biểu',
  viewInfo: 'Xem thông tin',
  reminder: 'Lời nhắc nhở',
  reminderTaskNotificationText: 'Nhiệm vụ đã lên lịch của bạn đến hạn hôm nay',
  inProgress: 'Trong tiến trình',
  scheduled: 'Lên kế hoạch',
  moveMidstayCleaning: 'Di chuyển ở lại dọn dẹp?',
  moveMidstayTitle: 'Move Midstay ',
  moveMidstayDescription: 'Bạn có chắc chắn muốn chuyển công việc dọn dẹp giữa chừng sang {{date}} không?',
  moveMidstayNotPossible: 'Xin lỗi! Việc dọn phòng giữa ngày này không thể dời sang ngày khác!',
  moveMidstayNotification: 'Quá trình dọn dẹp lưu trú đã được chuyển thành công sang {{date}}.',
  pleaseContactOps: 'Vui lòng liên hệ với nhóm Vận hành',
  midCleanMovedTo: 'Quá trình dọn dẹp được chuyển đến: {{value}}',
  optional: 'không bắt buộc',
  hskDelay: 'HSK chậm trễ',
  hskDelays: 'HSK chậm trễ',
  hskDelayMessage: 'Cập nhật thời gian nhận phòng cho phòng {{number}}',
  notStarted: 'Chưa bắt đầu',
  resolved: 'Đã giải quyết',
  noDamageReportsToday: 'Không có báo cáo thiệt hại cho ngày hôm nay',
  yourTask: 'Nhiệm vụ của bạn',
  yourTaskGX: 'Nhiệm vụ của bạn từ GX',
  damageReportedByGX: 'Thiệt hại được báo cáo bởi GX',
  stay: 'Ngày lưu trú',
  checkOut: 'Thời gian trả phòng',
  noPermission: 'Bạn không có quyền, vui lòng liên hệ hỗ trợ.',
  approvedCheckoutTime: 'Thời gian thanh toán đã được chấp thuận.',
  requestedCheckoutTime: 'Thời gian trả phòng yêu cầu',
  checkinTime: 'Thời gian nhận phòng',
  commentForHousekeeping: 'Bình luận cho bộ phận quản lý nhà ở',
  numberOfApprovedLCOs: 'Số lượng LCO được chấp thuận.',
  notSet: 'Chưa được thiết lập',
  vip: 'VIP',
  extraService: 'Dịch vụ bổ sung',
  reservationMissing: 'Đặt chỗ có thể đã bị hủy, hãy chuyển sang tab Tổng quát thay vì vậy.',
  confirmFor: 'Xác nhận cho {{date}}',
  confirmAnotherDay: 'Xác nhận cho ngày khác',
  taskConfirmed: 'Bạn đã xác nhận nhiệm vụ cho {{date}}.',
  confirmYourTask: 'Xác nhận nhiệm vụ của bạn',
  confirmYourTaskGX: 'Xác nhận nhiệm vụ của bạn từ GX',
  confirmHighPriorityTaskMessage:
    'Bạn có thực sự muốn đặt ngày hết hạn là hôm nay không? Có khả năng công việc sẽ không được xác nhận và hoàn thành trong ngày hôm nay.',
  setTomorrow: 'Đặt ngày mai',
  keepToday: 'Giữ ngày hôm nay',
  createTask: 'Tạo nhiệm vụ',
  confirmationNeeded: 'Xác nhận cần thiết.',
  pictureTooBlurry: `Hình ảnh quá mờ. Vui lòng chụp ảnh rõ ràng và tải lên.`,
  tryAgain: `Thử lại`,
  addDescription: `Thêm mô tả`,
  togglePushNotificationsLabel: `Thông báo đẩy cho iOS.`,
  roomRack: `Phòng`,
  newSlot: `Bảo trì mới`,
  searchByRoomNumber: `Số phòng`,
  reservation: `Đặt phòng`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Loại khe bảo trì`,
  selectType: `Chọn loại`,
  reason: `Lý do`,
  selectReason: `Chọn lý do`,
  selectDateAndTime: `Ngày và giờ`,
  startDate: `Ngày bắt đầu`,
  startTime: `Thời gian bắt đầu`,
  endDate: `Ngày kết thúc`,
  endTime: `Thời gian kết thúc`,
  room: `Phòng`,
  selectRoom: `Chọn phòng`,
  cleanAfterBlock: `Cần làm sạch sau slot`,
  addSlot: `Thêm khe bảo trì`,
  updateSlot: `Cập nhật khe`,
  errorStartDatePastSlotForm: `Ngày bắt đầu không thể ở quá khứ`,
  errorEndDatePastSlotForm: `Ngày kết thúc không thể trước ngày bắt đầu`,
  errorEndTimePastSlotForm: `Thời gian kết thúc không thể sớm hơn thời gian bắt đầu`,
  errorMinDurationSlotForm: `Thời gian tối thiểu là 30 phút`,
  errorOverbookedSlotForm: `Khe có thể dẫn đến việc đặt phòng quá chừng vào một số ngày được chọn. Tạo nó chỉ khi thực sự cần thiết, nếu không hãy chọn một khoảng thời gian khác.`,
  outOfOrderInfo: `OOO - Out of Order. Nên được sử dụng khi một phòng không hoạt động và không thể sử dụng bởi khách.`,
  outOfServiceInfo: `OOS - Out of Service. Cho phép bán phòng. Phòng được đánh dấu là Out of Service hoàn toàn hoạt động và chỉ có những khuyết điểm nhỏ về thẩm mỹ.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Được sử dụng bởi đội Launch, cho các kế hoạch bảo trì dài hạn, chẳng hạn như cải tạo cả tầng.`,
  maintenanceReason: `Bảo trì`,
  cleaningIssueReason: `Vấn đề vệ sinh`,
  roomMoveBlockReason: `Chuyển phòng/khách sạn`,
  bedBugsReason: `Con vét con`,
  smokingReason: `Hút thuốc`,
  vipGuestReason: `Khách hàng VIP`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: chiến lược`,
  rampUpRnrReason: `Ramp-up: phòng chưa sẵn sàng`,
  hoReason: `Tối ưu hóa HSK`,
  othersReason: `Lý do khác`,
  createdInApaleo: `Được tạo ra trong Apaleo`,
  reasonNotApaleo: `\`Tạo trong Apaleo\` không phải là lý do hợp lệ. Vui lòng chọn một lý do khác.`,
  quarantineReason: `Cách ly`,
  slotLog: `Log`,
  deleteSlot: `Xóa khe bảo trì`,
  deleteSlotLabel: `Vui lòng cung cấp lý do xóa`,
  errorDeleteSlot: `Bạn không thể xóa slot.`,
  errorDeleteInfoSlot: `Không thể xóa một khoảng thời gian đang diễn ra, thay vào đó bạn có thể rút ngắn nó.`,
  fieldRequired: `{{field}}: trường là bắt buộc`,
  from: `từ `,
  until: `đến ngày `,
  issueResolveDeleteReason: `Vấn đề đã được giải quyết / không cần thiết slot`,
  createdByMistakeDeleteReason: `Khẻo tạo theo sự nhầm lẫn`,
  guestRejectedMoveDeleteReason: `Khách từ chối di chuyển phòng`,
  guestAcceptedMoveDeleteReason: `Khách hàng chấp nhận chuyển phòng`,
  deleteReasonIsRequired: `Lý do xóa là bắt buộc`,
  editSlot: `Chỉnh sửa khe`,
  earlyCheckinByHour: `ECI - dọn dẹp vào {{hour}}:00`,
  paidEcis: `ECIs đã thanh toán vào lúc {{hour}}:00`,
  invalidTimeFormat: `Định dạng {{field}} không hợp lệ`,
};
