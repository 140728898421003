import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Baderomsvifte bråker',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Lys fungerer ikke',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Vasken er tett',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Dusjsluk er tett',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Baderomsfilter',
  [StandardizedDamageReport.SINK]: 'Vask',
  [StandardizedDamageReport.TOILET]: 'Toalett',
  [StandardizedDamageReport.SHOWER]: 'Dusj',
  [StandardizedDamageReport.STOVE]: 'Komfyr',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Kjøleskap/Minibar',
  [StandardizedDamageReport.DOOR]: 'Dør',
  [StandardizedDamageReport.WINDOW]: 'Vindu',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Lyspære',
  [StandardizedDamageReport.CHAIRS]: 'Stoler',
  [StandardizedDamageReport.HEATING]: 'Oppvarming',
  [StandardizedDamageReport.TV]: 'TV',
  [StandardizedDamageReport.TABLES]: 'Bord',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'HSK-team',
  [Team.MAINTENANCE]: 'Vedlikeholdsteam',
  [Team.OPERATIONS]: 'Operasjonsteam',
  [Team.ICT]: 'ICT',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Kjøkken',
  [DamageReportArea.BED]: 'Seng',
  [DamageReportArea.BATHROOM]: 'Baderom',
  [DamageReportArea.SEATING_AREA]: 'Sitteområde',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Vegger/Tak/Gulv',
  [DamageReportArea.ICT]: 'IKT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Ren',
  [DamageReportActionRequired.REPLACE]: 'Erstatt',
  [DamageReportActionRequired.REPAIR]: 'Reparer',
  [DamageReportActionRequired.CHECK]: 'Kryss av',
  [DamageReportActionRequired.OTHER]: 'Annen',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Standard',
  [TaskPriority.HIGH]: 'Høy',
};

const popupNotifications = {
  taskDeletionNotification: 'Oppgaven har blitt slettet',
  lostAndFoundDeletionNotification: 'Gjenglemt - gjenstanden har blitt slettet',
  damageReportDeletionNotification: 'Skadrapport er slettet',
  taskCreationNotification: 'Oppgaven er opprettet',
  lostAndFoundCreationNotification: 'Gjenglemt - gjenstanden er registrert',
  damageReportCreationNotification: 'Skadrapport er opprettet',
  taskCompletionNotification: 'Oppgaven er fullført',
  lostAndFoundCompletionNotification: 'Gjenglemt - saken er løst',
  damageReportCompletionNotification: 'Skaderapporten er løst',
  taskUpdateNotification: 'Oppgaven er oppdatert',
  lostAndFoundUpdateNotification: 'Gjenglemt - gjenstanden er oppdatert',
  damageReportUpdateNotification: 'Skaderapporten er oppdatert',
  pleaseSelectRoomsNotification: 'Velg rom',
  noShowReportNotification: 'No-show rapportert for rom {{number}}',
  checkInReportNotification: 'Inn-sjekk rapportert for rom {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Skaderapport planlagt for {{date}}',
  hskDelayNotification: 'HSK - forsinkelse for rom {{number}} rapportert. Innsjekkingstid er oppdatert til {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Annenhver uke',
  [CleaningInterval.DAILY]: 'Daglig',
  [CleaningInterval.NONE]: 'Ingen',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Flere filtre',
  resetAllFilters: 'Tilbakestill alle filtrene',
  reset: 'Nullstill',
  cleaned: 'Renset',
  allRoomsCleaned: `Alle rom rengjort`,
  dirty: 'Skitten',
  readyToClean: 'Klar til å rengjøre',
  stayoverReadyToClean: 'Opphold - klar til å rengjøre',
  occupiedCleaningToday: 'Opptatt - rengjøring i dag',
  readyToInspect: 'Klar til å sjekkes',
  stayoverInspection: 'Oppholdssjekk',
  vacant: 'Ledig',
  occupied: 'Opptatt',
  clean: 'Ren',
  daily: 'Daglig',
  weekly: 'Ukentlig',
  biweekly: 'Annenhver uke',
  monthly: 'Månedlig',
  none: 'Ingen',
  tasks: 'Oppgaver',
  notifications: 'Varsler',
  more: 'Mer',
  select: 'Plukke ut',
  property: 'Eiendom',
  result: 'Resultat',
  results: 'Resultater',
  properties: 'Egenskaper',
  rooms: 'Rom',
  team: 'Team',
  dueDate: 'Tidsfrist',
  date: 'Dato',
  close: 'Lukk',
  search: 'Søk',
  login: 'Logg Inn',
  loading: 'Laster ...',
  anErrorHasOccurred: 'Det har oppstått en feil',
  chooseDate: 'Velg dato',
  selectDate: 'Velg dato',
  selectADate: 'Velg en dato',
  markAsClean: 'Merk som rent',
  markAsReadyToInspect: 'Merk som klar til å sjekkes',
  viewTasks: 'Se oppgaver',
  eci: 'ECI - tidlig ankomst',
  lco: 'LCO - sen utsjekk',
  snacks: 'SNACKS',
  roomLog: `Romlogg`,
  taskLog: `Oppgavelogg`,
  seeDetails: 'Se detaljer',
  assignedTasks: 'Tildelte oppgaver',
  delete: 'Slett',
  deleteTask: 'Slett oppgaven',
  deleteTaskConfirmationMessage: 'Er du sikker på at du vil slette denne oppgaven?',
  deleteTaskRepeatsConfirmationMessage:
    'Dette er en repeterende oppgave. Du kan bare slette kun dette eller de alle fremtidige oppgavene',
  deleteOnlyThisTask: 'Slett bare denne oppgaven',
  deleteRepeatingTask: 'Slett repeterende oppgave',
  yes: 'Ja',
  no: 'Nei',
  pleaseWaitAFewSeconds: 'Vent noen sekunder',
  deleting: 'Sletter',
  lostAndFound: 'Gjenglemt',
  current: 'Nåværende',
  archive: 'Arkiv',
  edit: 'Rediger',
  markAsResolved: 'Marker som løst',
  markAsUnresolved: 'Marker som uløst',
  add: 'Legg til',
  area: 'Område',
  addTask: 'Legg til oppgave',
  editTask: 'Rediger oppgave',
  seeTaskDetails: 'Se oppgave',
  savePicturesAndStartCleaning: 'Lagre og start rengjøring',
  savePictures: 'Lagre bilder',
  takePhoto: 'Ta bilde',
  addArea: 'Legg til område',
  addAreaDescription: 'Legg til et område på hotellet for denne oppgaven',
  selectProperty: 'Velg eiendom',
  selectRooms: 'Velg rom',
  selectTeam: 'Velg team',
  selectDueDate: 'Velg dato',
  hskTeam: 'HUSØK - team',
  save: 'Lagre',
  actionIsRequired: `Handling er påkrevd. Slett tittel og velg en handling fra listen.`,
  noActionSelected: `Ingen handling valgt`,
  changeSearchAction: `Prøv å endre søkeordet ditt`,
  noActionFound: `Ingen handling funnet`,
  selectActionHelp: `Finner du ikke en handling? Vennligst kontakt driftsteamet slik at de kan be om det.`,
  writeToSelectAction: 'Skriv for å velge en handling',
  writeTask: 'Skriv oppgave',
  repetition: 'Repetisjon',
  noRepetition: 'Ingen repetisjon',
  repetitionTime: 'Gjentagelsestid',
  doesNotRepeat: 'Gjentas ikke',
  day: 'Dag',
  week: 'Uke',
  month: 'Måned',
  repeatOn: 'Gjenta på',
  repeatsEvery: 'Gjentar hver',
  description: 'Beskrivelse',
  title: 'Tittel',
  addTitle: 'Legg til tittel',
  titleIsRequired: 'Tittel er påkrevd',
  titleMaxLength: 'Tittelen bør være mindre enn {{maxLength}} tegn',
  teamIsRequired: 'Teamet er påkrevd',
  openTasks: 'Åpne oppgaver',
  arrival: 'Ankomst',
  departure: 'Avreise',
  checkIn: 'Sjekk inn',
  times: 'Tidspunkt',
  guests: 'Gjester',
  name: 'Navn',
  additionalInfo: 'Tilleggsinformasjon',
  addLostAndFound: 'Legg til gjenglemt',
  addDamageReport: 'Legg til skaderapport',
  unitIsRequired: 'Enhet er påkrevd',
  addItem: 'Legg til vare',
  editItem: 'Rediger element',
  startCleaning: 'Begynn å rengjøre',
  endCleaning: 'Avslutte rengjøring',
  endCleaningMessage: 'Det er 1 åpen oppgave. Er du sikker på at du vil fullføre rengjøringen?',
  endCleaningMessage_plural: 'Det er {{count}} åpne oppgaver. Er du sikker på at du vil fullføre rengjøringen?',
  cancelAndViewTasks: 'Avbryt og se oppgaver',
  ignoreAndMarkAsReadyToInspect: 'Ignorer og marker som klar til å sjekkes',
  ignoreAndMarkAsCleaned: 'Ignorer og marker som ren',
  damageReports: 'Skaderapporter',
  confirm: 'Bekreft',
  selectRoomOrArea: 'Velg rom/område eller',
  createNewOne: 'Lag ny',
  today: 'I dag',
  tomorrow: 'I morgen',
  noTasksMessage: 'Ingen oppgave er lagt til',
  cleaningStatus: 'Rengjøring',
  logOut: 'Logg ut',
  language: 'Språk',
  selectLanguage: 'Velg språk',
  isStandardized: 'Er standardisert?',
  selectStandardizedTask: 'Velg standardisert oppgave',
  overdue: 'Forfalt',
  arrivals: 'Ankomster',
  memberArrivals: 'Member arrivals',
  departures: 'Avreiser',
  stayovers: 'Opphold',
  occupancy: 'OTB belegg',
  midstayCleaning: 'Mellomopphold rengjøring',
  roomsToSell: 'Rom å selge',
  editImages: 'Rediger bilder',
  toClean: 'å vaske',
  toInspect: 'å sjekke',
  noRoomsForCleaningToday: 'Ingen rom for rengjøring i dag',
  noShow: 'No show',
  noShowReportConfirmationMessage: 'Bekreft at romnummer {{number}} er no-show.',
  noShowReportQuestion: 'Sjekket gjesten inn i går?',
  checkInReportConfirmationMessage: 'Bekreft at gjesten sjekket inn i rom nr {{number}}.',
  noResultsSearchMessage: 'Ingen resultater samsvarer med søkekriteriene dine',
  cancel: 'Avbryt',
  selectCleaner: 'Velg renere',
  selectAll: 'Velg alle',
  floor: 'Gulv',
  notAssigned: 'Ikke tildelt',
  assignXRooms: 'Tildell {{total}} rom',
  assignRooms: 'Tildell rom',
  approvedLCOs: 'Godkjente LCO - sene utsjekk',
  cleaner: 'Renere',
  roomsCleanForToday: 'Alle rom er rene for i dag! 🎉🎉',
  viewDamageReports: 'Se skaderapporter',
  noTasks: 'Ingen oppgaver',
  noDamageReports: 'Ingen skaderapporter',
  dailyCleaningReport: 'Daglig rengjøringsrapport',
  stayover: 'Opphold',
  roomMoveFrom: 'Romflytting fra {{value}}',
  roomMoveTo: 'Rom flytt til {{value}}',
  unassignAll: 'Uordnede {{total}} alle',
  unassignRoomsConfirmationMessage: 'Er du sikker på at du ønsker å fjerne romtildelingen {{total}} rom?',
  unassignRoomsTitle: 'Ledige rom',
  noRoomAssigned: 'Ingen rom tildelt',
  action: 'Handling',
  hideActions: 'Skjul handlinger',
  showActions: 'Vis handlinger',
  actionsSelected: '{{total}} handling(er) valgt',
  disableActionTitleModal: 'Tilbakestill {{name}}-filteret',
  disableActionDescriptionModal:
    'For å bruke {{currentFilter}}-filteret, vennligst tilbakestill {{resetFilter}}-filteret.',
  actionRequired: 'Handling kreves',
  priority: 'Prioritet',
  lcoUntil: 'LCO til',
  created: 'Opprettet',
  startNow: 'Start nå',
  scheduleTime: 'Planlegg tid',
  viewInfo: 'Se info',
  reminder: 'Påminnelse',
  reminderTaskNotificationText: 'Din planlagte oppgave forfaller i dag',
  inProgress: 'I prosess',
  scheduled: 'Planlagt',
  moveMidstayCleaning: 'Flytte rengjøring av oppholdet?',
  moveMidstayTitle: 'Flytt midtstaben',
  moveMidstayDescription: 'Er du sikker på at du vil flytte rengjøringen til {{date}}?',
  moveMidstayNotPossible: 'Beklager! Mellomoppholds - rengjøringen for dette rommet kan ikke flyttes til en annen dag!',
  moveMidstayNotification: 'Rengjøring av opphold er blitt flyttet til {{date}}.',
  pleaseContactOps: 'Ta kontakt med operasjonsteamet',
  midCleanMovedTo: 'Rengjøring av opphold flyttet til: {{value}}',
  optional: 'valgfri',
  hskDelay: 'HUSØK - forsinkelse',
  hskDelays: 'HUSØK - forsinkelser',
  hskDelayMessage: 'Oppdater innsjekkingstid for rom {{number}}',
  notStarted: 'Ikke begynt',
  resolved: 'Løst',
  noDamageReportsToday: 'Ingen skadeapporter for i dag',
  yourTask: 'Din oppgave',
  yourTaskGX: 'Oppgaven din fra GX',
  damageReportedByGX: 'Skader rapportert av GX',
  stay: 'Oppholdsdatoer',
  checkOut: 'Utsjekkingstid',
  noPermission: 'Du har ikke tillatelse, vennligst kontakt support.',
  approvedCheckoutTime: 'Godkjent utsjekkingstid',
  requestedCheckoutTime: 'Requested checkout time',
  checkinTime: 'Innsjekkingstid',
  commentForHousekeeping: 'Kommentar til renhold',
  numberOfApprovedLCOs: 'Antall godkjente LCO-er',
  notSet: 'Ikke satt',
  vip: 'VIP',
  extraService: 'Ekstra service',
  reservationMissing: 'Reservasjonen er mest sannsynlig kansellert, bytt til Generelt-fanen i stedet.',
  confirmFor: 'Bekreft for {{date}}',
  confirmAnotherDay: 'Bekreft en annen dag',
  taskConfirmed: 'Du har bekreftet oppgaven for {{date}}',
  confirmYourTask: 'Bekreft oppgaven din',
  confirmYourTaskGX: 'Bekreft oppgaven din fra GX',
  confirmHighPriorityTaskMessage:
    'Vil du virkelig sette forfallsdatoen til i dag? Det er sannsynlig at oppgaven ikke vil bli bekreftet og fullført i dag.',
  setTomorrow: 'Sett for i morgen',
  keepToday: 'La stå for i dag',
  createTask: 'Opprett oppgave',
  confirmationNeeded: 'Bekreftelse trengs',
  pictureTooBlurry: `Bildet er for uklart. Vennligst ta et tydelig bilde og last det opp.`,
  tryAgain: `Prøv igjen`,
  addDescription: `Legg til beskrivelse`,
  togglePushNotificationsLabel: `Pushvarsler for iOS`,
  roomRack: `Rom`,
  newSlot: `Nytt slot`,
  searchByRoomNumber: `Romnummer`,
  reservation: `Reservasjon`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Slottype`,
  selectType: `Velg type`,
  reason: `Årsak`,
  selectReason: `Velg grunn`,
  selectDateAndTime: `Dato og tid`,
  startDate: `Startdato`,
  startTime: `Starttid`,
  endDate: `Sluttdato`,
  endTime: `Sluttid`,
  room: `Rom`,
  selectRoom: `Velg rom`,
  cleanAfterBlock: `Rengjøring nødvendig etter slot`,
  addSlot: `Legg til slot`,
  updateSlot: `Oppdater slotet`,
  errorStartDatePastSlotForm: `Startdato kan ikke være i fortiden`,
  errorEndDatePastSlotForm: `Sluttdato kan ikke være før startdato`,
  errorEndTimePastSlotForm: `Sluttidspunktet kan ikke være før starttidspunktet`,
  errorMinDurationSlotForm: `Minimum varighet er 30 min`,
  errorOverbookedSlotForm: `Slotet kan føre til overbooking på valgte dag(er). Opprett det bare hvis det er virkelig nødvendig, ellers velg en annen tidsperiode.`,
  outOfOrderInfo: `OOO - Out of Order. Skal brukes når et rom ikke fungerer og ikke kan brukes av en gjest.`,
  outOfServiceInfo: `OOS - Out of Service. Tillater rommet å selges. Et rom merket som Out of Service er fullt funksjonelt og har bare mindre kosmetiske feil.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Brukes av Launch-teamet for langsiktige vedlikeholdsplaner, som renovering av en hel etasje.`,
  maintenanceReason: `Vedlikehold`,
  cleaningIssueReason: `Rengjøringsproblem`,
  roomMoveBlockReason: `Rom/hotellflytting`,
  bedBugsReason: `Bed bugs`,
  smokingReason: `Røyking`,
  vipGuestReason: `VIP-gjest`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: strategisk`,
  rampUpRnrReason: `Ramp-up: rom ikke klart`,
  hoReason: `HSK-optimalisering`,
  othersReason: `Annen grunn`,
  createdInApaleo: `Opprettet i Apaleo`,
  reasonNotApaleo: `Opprettet i Apaleo er ikke en gyldig grunn. Vennligst velg en annen.`,
  quarantineReason: `Karantene`,
  slotLog: `Log`,
  deleteSlot: `Slett sloten`,
  deleteSlotLabel: `Vennligst oppgi sletteårsaken`,
  errorDeleteSlot: `Du kan ikke slette sloten.`,
  errorDeleteInfoSlot: `En pågående slot kan ikke slettes, men den kan forkortes.`,
  fieldRequired: `{{field}}: feltet er påkrevd`,
  from: `fra `,
  until: `til `,
  issueResolveDeleteReason: `Problemet er løst / slot er ikke nødvendig`,
  createdByMistakeDeleteReason: `Spalte opprettet ved en feil`,
  guestRejectedMoveDeleteReason: `Gjest avviste romflyt`,
  guestAcceptedMoveDeleteReason: `Gjest aksepterte romflyt`,
  deleteReasonIsRequired: `Årsak til sletting er påkrevd`,
  editSlot: `Rediger spor`,
  earlyCheckinByHour: `ECI - rengjør innen {{hour}}:00`,
  paidEcis: `Betalt ECIs kl. {{hour}}:00`,
  invalidTimeFormat: `{{felt}}-formatet er ugyldig`,
};
