import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Ventilador de baño ruidoso',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'La luz no funciona',
  [StandardizedDamageReport.SINK_BLOCKED]: 'El fregadero está bloqueado',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'El desagüe de la ducha está bloqueado',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filtro de baño',
  [StandardizedDamageReport.SINK]: 'Hundir',
  [StandardizedDamageReport.TOILET]: 'Inodoro',
  [StandardizedDamageReport.SHOWER]: 'Ducha',
  [StandardizedDamageReport.STOVE]: 'Cocina',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Frigorífico / Minibar',
  [StandardizedDamageReport.DOOR]: 'Puerta',
  [StandardizedDamageReport.WINDOW]: 'Ventana',
  [StandardizedDamageReport.AC]: 'C.A.',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Bombilla',
  [StandardizedDamageReport.CHAIRS]: 'Sillas',
  [StandardizedDamageReport.HEATING]: 'Calefacción',
  [StandardizedDamageReport.TV]: 'televisor',
  [StandardizedDamageReport.TABLES]: 'Mesas',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Equipo Housekeping',
  [Team.MAINTENANCE]: 'Equipo Mantenimiento',
  [Team.OPERATIONS]: 'Equipo Operaciones',
  [Team.ICT]: 'ICT',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Cocina',
  [DamageReportArea.BED]: 'Cama',
  [DamageReportArea.BATHROOM]: 'Cuarto de baño',
  [DamageReportArea.SEATING_AREA]: 'Área para sentarse',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Paredes / Techo / Suelo',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Limpio',
  [DamageReportActionRequired.REPLACE]: 'Reemplazar',
  [DamageReportActionRequired.REPAIR]: 'Reparar',
  [DamageReportActionRequired.CHECK]: 'Cheque',
  [DamageReportActionRequired.OTHER]: 'Otro',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Estándar',
  [TaskPriority.HIGH]: 'Elevado',
};

const popupNotifications = {
  taskDeletionNotification: 'La tarea ha sido eliminada con éxito',
  lostAndFoundDeletionNotification: 'Objeto perdido y encontrado ha sido eliminado con éxito ',
  damageReportDeletionNotification: 'Reporte de daños ha sido eliminado con éxito ',
  taskCreationNotification: 'Tarea creada con éxito',
  lostAndFoundCreationNotification: 'Item perdido y encontrado ha sido creado con éxito ',
  damageReportCreationNotification: 'Deporte de daños ha sido creado con éxito ',
  taskCompletionNotification: 'La tarea ha sido completada con éxito',
  lostAndFoundCompletionNotification: 'Objeto perdido y encontrado ha sido resuelto con éxito ',
  damageReportCompletionNotification: 'Reporte de daños ha sido resuelto con éxito ',
  taskUpdateNotification: 'La tarea ha sido actualizada con éxito ',
  lostAndFoundUpdateNotification: 'Objeto perdido y encontrado ha sido actualizado con éxito ',
  damageReportUpdateNotification: 'Reporte de daños ha sido actualizado con éxito ',
  pleaseSelectRoomsNotification: 'Por favor selecciona las habitaciones ',
  noShowReportNotification: 'No show reportado con éxito {{number}}',
  checkInReportNotification: 'Check in successfully reported for room {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Informe de daños programado con éxito para {{date}}',
  hskDelayNotification: 'Retraso HSK exitoso para la habitación {{number}}. La hora de entrada se actualiza a {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Más filtros',
  resetAllFilters: 'Restablecer todos los filtros',
  reset: 'Restablecer',
  cleaned: 'Limpio ',
  allRoomsCleaned: `Todas las habitaciones limpiadas`,
  dirty: 'Sucio ',
  readyToClean: 'Listo para limpiar ',
  stayoverReadyToClean: 'Estancia - listo para limpiar ',
  occupiedCleaningToday: 'Ocupado - limpieza hoy ',
  readyToInspect: 'Listo para inspeccionar ',
  stayoverInspection: 'Inspección estancia ',
  vacant: 'Vacante',
  occupied: 'Ocupado ',
  clean: 'Limpio ',
  daily: 'Diario ',
  weekly: 'Semanal',
  biweekly: 'Quincenal ',
  monthly: 'Mensual',
  none: 'No',
  tasks: 'Tareas',
  notifications: 'Notificaciones ',
  more: 'Más',
  select: 'Seleccionar ',
  property: 'Propiedad',
  result: 'Resultado ',
  results: 'Resultados ',
  properties: 'Propiedades',
  rooms: 'Habitaciones ',
  team: 'Equipo ',
  dueDate: 'Fecha de entrega ',
  date: 'Fecha ',
  close: 'Cerrado',
  search: 'Buscar',
  login: 'Acceso ',
  loading: 'Cargando...',
  anErrorHasOccurred: 'Ha ocurrido un error ',
  chooseDate: 'Elija la fecha ',
  selectDate: 'Selecciona la fecha ',
  selectADate: 'Selecciona la fecha ',
  markAsClean: 'Marcar como limpio',
  markAsReadyToInspect: 'Marcar como listo para inspeccionar ',
  viewTasks: 'Ver las tareas',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Registro de habitaciones`,
  taskLog: `Registro de tareas`,
  seeDetails: 'Ver detalles ',
  assignedTasks: 'Tareas asignadas',
  delete: 'Borrar',
  deleteTask: 'Borrar tarea',
  deleteTaskConfirmationMessage: '¿Estás seguro que quieres borrar esta tarea? ',
  deleteTaskRepeatsConfirmationMessage:
    'Esta es una tarea repetida. Puedes borrar solo esta o también la tarea repetida en el futuro',
  deleteOnlyThisTask: 'Borrar solo esta tarea',
  deleteRepeatingTask: 'Borrar tarea repetida',
  yes: 'Si',
  no: 'No',
  pleaseWaitAFewSeconds: 'Por favor espere unos segundos ',
  deleting: 'Borrando',
  lostAndFound: 'Perdido y encontrado',
  current: 'Actual',
  archive: 'Archivar ',
  edit: 'Editar',
  markAsResolved: 'Marcar como resuleto ',
  markAsUnresolved: 'Marcar como no resuleto',
  add: 'Añadir',
  area: 'Área',
  addTask: 'Añadir tarea',
  editTask: 'Editar tarea',
  seeTaskDetails: 'Ver tarea',
  savePicturesAndStartCleaning: 'Guardar y comenzar a limpiar',
  savePictures: 'Guardar imágenes',
  takePhoto: 'Tomar foto',
  addArea: 'Añadir área',
  addAreaDescription: 'Añadir un área del hotel para esta tarea',
  selectProperty: 'Seleccionar propiedad',
  selectRooms: 'Seleccionar habitaciones',
  selectTeam: 'Seleccionar equipo',
  selectDueDate: 'Seleccionar fecha ',
  hskTeam: 'Equipo HSK ',
  save: 'Guardar',
  actionIsRequired: `Se requiere acción. Elimine el título y seleccione una acción de la lista.`,
  noActionSelected: `Acción no seleccionada`,
  changeSearchAction: `Intenta cambiar tu término de búsqueda`,
  noActionFound: `No se encontró ninguna acción`,
  selectActionHelp: `¿No puedes encontrar una acción? Por favor, contacta al equipo de Operaciones para que puedan solicitarla.`,
  writeToSelectAction: 'Escriba para seleccionar una acción',
  writeTask: 'Escribir tarea',
  repetition: 'Repetición',
  noRepetition: 'Sin repetición',
  repetitionTime: 'Tiempo de repetición ',
  doesNotRepeat: 'No repetir ',
  day: 'Día ',
  week: 'Semana',
  month: 'Mes',
  repeatOn: 'Repetir en',
  repeatsEvery: 'Repetir cada',
  description: 'Descripción ',
  title: 'Título',
  addTitle: 'Añadir título',
  titleIsRequired: 'El título es requerido.',
  titleMaxLength: 'El título debe tener menos de {{maxLength}} caracteres',
  teamIsRequired: 'Se requiere equipo',
  openTasks: 'Abrir tareas',
  arrival: 'Llegada',
  departure: 'Salida',
  checkIn: 'Check In',
  times: 'Tiempos',
  guests: 'Huéspedes',
  name: 'Nombre',
  additionalInfo: 'Información adicional ',
  addLostAndFound: 'Añadir perdido y encontrado',
  addDamageReport: 'Añadir informe de daños',
  unitIsRequired: 'Se requiere unidad ',
  addItem: 'Añadir objeto',
  editItem: 'Editar objeto',
  startCleaning: 'Empezar a limpiar',
  endCleaning: 'Acabar de limpiar ',
  endCleaningMessage: 'Hay 1 tarea abierta. ¿Estás seguro que quieres acabar de limpiar?',
  endCleaningMessage_plural: 'Hay {{count}} tareas abiertas. ¿Estás seguro que quieres acabar de limpiar?',
  cancelAndViewTasks: 'Cancelar y ver tareas',
  ignoreAndMarkAsReadyToInspect: 'Ignorar y marcar como lista para inspeccionar ',
  ignoreAndMarkAsCleaned: 'Ignorar y marcar como limpia',
  damageReports: 'Reporte de daños ',
  confirm: 'Confirmar',
  selectRoomOrArea: 'Seleccionar habitación/área o ',
  createNewOne: 'crear una nueva',
  today: 'Hoy',
  tomorrow: 'Mañana',
  noTasksMessage: 'No se han añadido tareas',
  cleaningStatus: 'Limpiar ',
  logOut: 'Salir',
  language: 'Idioma',
  selectLanguage: 'Seleccionar idioma',
  isStandardized: 'Está Estandarizada?',
  selectStandardizedTask: 'Seleccionar tarea estandarizada',
  overdue: 'Atrasado',
  arrivals: 'Llegadas',
  memberArrivals: 'Member arrivals',
  departures: 'Salidas',
  stayovers: 'Limpieza de media estancia ',
  occupancy: 'OTB Ocupación ',
  midstayCleaning: 'Limpieza media estancia ',
  roomsToSell: 'Habitaciones para vender',
  editImages: 'Editar imágenes',
  toClean: 'para limpiar',
  toInspect: 'para inspeccionar ',
  noRoomsForCleaningToday: 'No hay habitaciones para limpiar hoy ',
  noShow: 'No show',
  noShowReportConfirmationMessage: 'Por favor confirmar que la habitación número {{number}} es un no show.',
  noShowReportQuestion: '¿El huésped se registró ayer?',
  checkInReportConfirmationMessage: 'Confirme que el huésped se registró en el número de habitación {{number}}.',
  noResultsSearchMessage: 'No hay resultados para su búsqueda ',
  cancel: 'Cancelar ',
  selectCleaner: 'Elegir limpiador ',
  selectAll: 'Seleccionar Todos',
  floor: 'Planta',
  notAssigned: 'No asignado',
  assignXRooms: 'Asignadas {{total}} habitaciones',
  assignRooms: 'Asignar habitaciones ',
  approvedLCOs: 'LCO aprobados',
  cleaner: 'Limpiador',
  roomsCleanForToday: '¡Todas las habitaciones están limpias por hoy! 🎉🎉',
  viewDamageReports: 'Ver informes de daños',
  noTasks: 'Sin tareas',
  noDamageReports: 'Sin informes de daños',
  dailyCleaningReport: 'Informe de limpieza diaria',
  stayover: 'Stayover',
  roomMoveFrom: 'Movimiento de habitación de {{value}}',
  roomMoveTo: 'Mover la habitación a {{value}}',
  unassignAll: 'Desasignar todo',
  unassignRoomsConfirmationMessage: '¿Está seguro de que desea anular la asignación de {{total}} salas?',
  unassignRoomsTitle: 'Anular asignación de salas',
  noRoomAssigned: 'No hay habitación asignada',
  action: 'Acción',
  hideActions: 'Ocultar acciones',
  showActions: 'Mostrar acciones',
  actionsSelected: '{{total}} acción(es) seleccionada(s)',
  disableActionTitleModal: 'Reiniciar el filtro {{name}}',
  disableActionDescriptionModal:
    'Para usar el filtro {{currentFilter}}, por favor restablezca el filtro {{resetFilter}}.',
  actionRequired: 'Acción requerida',
  priority: 'Prioridad',
  lcoUntil: 'LCO hasta',
  created: 'Creado',
  startNow: 'Empezar ahora',
  scheduleTime: 'Tiempo programado',
  viewInfo: 'Ver información',
  reminder: 'Recordatorio',
  reminderTaskNotificationText: 'Tu tarea programada vence hoy',
  inProgress: 'En curso',
  scheduled: 'Programado',
  moveMidstayCleaning: '¿Mover la limpieza de la estancia?',
  moveMidstayTitle: 'Move Midstay ',
  moveMidstayDescription: '¿Está seguro de que le gustaría trasladar la limpieza a mitad de estancia a {{date}}?',
  moveMidstayNotPossible:
    '¡Lo siento! ¡La limpieza a mitad de estancia de esta habitación no se puede trasladar a otro día!',
  moveMidstayNotification: 'La limpieza de la estancia se ha trasladado correctamente a {{date}}.',
  pleaseContactOps: 'Comuníquese con el equipo de operaciones',
  midCleanMovedTo: 'La limpieza de la estancia se trasladó a: {{value}}',
  optional: 'Opcional',
  hskDelay: 'Retraso HSK',
  hskDelays: 'Retrasos HSK',
  hskDelayMessage: 'Actualizar la hora de entrada de la habitación {{number}}',
  notStarted: 'No empezado',
  resolved: 'Resuelto',
  noDamageReportsToday: 'No hay informes de daños para hoy',
  yourTask: 'Tu tarea',
  yourTaskGX: 'Tu tarea desde GX',
  damageReportedByGX: 'Daño reportado por GX',
  stay: 'Fechas de estadía',
  checkOut: 'Hora de salida',
  noPermission: 'No tienes permiso, por favor contacta al soporte',
  approvedCheckoutTime: 'Hora de salida aprobada',
  requestedCheckoutTime: 'Hora de salida solicitada',
  checkinTime: 'Hora de registro',
  commentForHousekeeping: 'Comentario para el servicio de limpieza.',
  numberOfApprovedLCOs: 'Número de LCOs aprobados',
  notSet: 'No establecido',
  vip: 'VIP',
  extraService: 'Servicio adicional',
  reservationMissing: 'La reserva probablemente está cancelada, cambia a la pestaña General en su lugar.',
  confirmFor: 'Confirmar Para {{date}}',
  confirmAnotherDay: 'Confirmar otro día',
  taskConfirmed: 'Has confirmado la tarea para {{date}}.',
  confirmYourTask: 'Confirma tu tarea.',
  confirmYourTaskGX: 'Confirma tu tarea de GX',
  confirmHighPriorityTaskMessage:
    '¿Realmente quieres establecer la fecha de vencimiento para hoy? Es probable que la tarea no sea confirmada ni completada hoy.',
  setTomorrow: 'Establecer para mañana',
  keepToday: 'Mantén hoy',
  createTask: 'Crear tarea',
  confirmationNeeded: 'Confirmación necesaria',
  pictureTooBlurry: `La imagen está demasiado borrosa. Por favor, tome una foto clara y súbala.`,
  tryAgain: `Intenta de nuevo`,
  addDescription: `Agregar descripción`,
  togglePushNotificationsLabel: `Notificaciones push para iOS`,
  roomRack: `Room rack`,
  newSlot: `Nuevo slot`,
  searchByRoomNumber: `Número de habitación`,
  reservation: `Reserva`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Tipo de slot`,
  selectType: `Seleccionar tipo`,
  reason: `Razón`,
  selectReason: `Seleccionar motivo`,
  selectDateAndTime: `Fecha y hora`,
  startDate: `Fecha de inicio`,
  startTime: `Hora de inicio`,
  endDate: `Fecha de finalización`,
  endTime: `Hora de finalización`,
  room: `Habitación`,
  selectRoom: `Seleccionar habitación`,
  cleanAfterBlock: `Limpieza necesaria después del slot`,
  addSlot: `Agregar el slot`,
  updateSlot: `Actualizar el slot`,
  errorStartDatePastSlotForm: `La fecha de inicio no puede ser en el pasado.`,
  errorEndDatePastSlotForm: `La fecha de finalización no puede ser anterior a la fecha de inicio`,
  errorEndTimePastSlotForm: `La hora de finalización no puede ser anterior a la hora de inicio`,
  errorMinDurationSlotForm: `La duración mínima del slot es de 30 minutos`,
  errorOverbookedSlotForm: `El slot podría provocar una sobreventa en el(s) día(s) seleccionado(s). Créelo solo si es realmente necesario, de lo contrario seleccione un período de tiempo diferente.`,
  outOfOrderInfo: `OOO - Out of Order. Debe usarse cuando una habitación no es funcional y no puede ser utilizada por un huésped.`,
  outOfServiceInfo: `OOS - Out of Service. Permite que la habitación sea vendida. Una habitación marcada como Out of Service es totalmente funcional y tiene solo defectos estéticos menores.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Usado por el equipo de Launch para planes de mantenimiento prolongados, como la renovación de un piso completo.`,
  maintenanceReason: `Mantenimiento`,
  cleaningIssueReason: `Problema de limpieza`,
  roomMoveBlockReason: `Cambio de habitación/hotel`,
  bedBugsReason: `Chinches de cama`,
  smokingReason: `Fumar`,
  vipGuestReason: `Huésped VIP`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: estratégico`,
  rampUpRnrReason: `Ramp-up: habitación no lista`,
  hoReason: `Optimización HSK`,
  othersReason: `Otra razón`,
  createdInApaleo: `Creado en Apaleo`,
  reasonNotApaleo: `\`Creado en Apaleo\` no es una razón válida. Por favor, selecciona una diferente.`,
  quarantineReason: `Cuarentena`,
  slotLog: `Log`,
  deleteSlot: `Eliminar el slot`,
  deleteSlotLabel: `Por favor, proporcione el motivo de eliminación`,
  errorDeleteSlot: `No puedes eliminar el slot.`,
  errorDeleteInfoSlot: `Un slot en curso no puede ser eliminado, en su lugar puede ser acortado.`,
  fieldRequired: `{{field}}: el campo es obligatorio`,
  from: `desde `,
  until: `hasta `,
  issueResolveDeleteReason: `Problema ha sido resuelto / slot no necesario`,
  createdByMistakeDeleteReason: `Slot creado por error`,
  guestRejectedMoveDeleteReason: `Huésped rechazó cambio de habitación`,
  guestAcceptedMoveDeleteReason: `Huésped aceptó cambio de habitación`,
  deleteReasonIsRequired: `Se requiere motivo de eliminación`,
  editSlot: `Editar slot`,
  earlyCheckinByHour: `ECI - limpiar antes de las {{hour}}:00`,
  paidEcis: `ECIs pagados a las {{hour}}:00`,
  invalidTimeFormat: `El formato de {{field}} es inválido`,
};
