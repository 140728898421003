import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@molecules/Dialog';
import { UnitInfoType } from '@typings/types';
import IconWithText from '@molecules/IconWithText';
import icons from '@constants/icons';
import {
  BriefcaseIcon,
  ClipboardListIcon,
  ClockIcon,
  DocumentTextIcon,
  EyeOffIcon,
  PlusCircleIcon,
  StarIcon,
} from '@heroicons/react/outline';
import unitsApi from '@api/unitsApi';
import { AutomatedTaskType, ServerStateKey, TaskType, UnitStatus } from '@typings/enums';
import ActionItem from '@molecules/ActionItem';
import {
  canDelayHskStatus,
  canMarkAsCleanUnitStatuses,
  canMarkForInspectionUnitStatuses,
  canMoveMidstayCleaningStatus,
} from '@utils/unitUtils';
import { useMutation, useQueryClient } from 'react-query';
import { ChevronRightIcon, DotsHorizontalIcon, XIcon } from '@heroicons/react/solid';
import useApiCall from '@utils/hooks/useApiCall';
import routes, { goToUnitDetails } from '@constants/routes';
import useAuth from '@utils/hooks/useAuth';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import { useTranslation } from 'react-i18next';
import useEndCleaning from '@utils/hooks/useEndCleaning';
import { formatDate, TIME_FORMAT } from '@utils/dateUtils';
import useMarkAsClean from '@utils/hooks/useMarkAsClean';
import useBreakpoint from '@utils/hooks/useBreakpoint';
import useNoShowReport from '@utils/hooks/useNoShowReport';
import useMoveMidstayClean from '@utils/hooks/useMoveMidstayClean';
import useHskDelay from '@utils/hooks/useHskDelay';
import usePropertiesState from '@context/propertiesContext';
import { useGetUnitDetails } from '@api/api';
import { groupTasksByType } from '@utils/taskUtils';
import KitchenUseTaskModal from '@organisms/KitchenUseTaskModal';
import useTaskActionsState from '@context/taskActionsContext';
import { roomLogsUrl } from '@constants/url';

interface Props {
  propertyExternalId: string;
  unit: UnitInfoType;
  setUnit: (unit?: UnitInfoType) => void;
}

export default function UnitActions({ propertyExternalId, unit, setUnit }: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { getTimeZone, selectedProperty } = usePropertiesState();
  const { isCleaner, isRoomChecker } = useAuth();
  const { isCleanerOnlyRole, isOperationsRole } = useRoleBasedUI();
  const { getTaskActionByAutomatedType } = useTaskActionsState();

  const [isKitchenUseModalOpen, setIsKitchenUseModalOpen] = useState<boolean>(false);

  const isCleanerOnly = unit && isCleanerOnlyRole(unit.propertyId);
  const isOperations = unit && isOperationsRole(unit.propertyId);

  const { data: unitDetails } = useGetUnitDetails({
    propertyId: unit.propertyId,
    unitId: unit.id,
    timeZone: getTimeZone(unit.propertyId),
  });

  const tasksByType = unitDetails ? groupTasksByType(unitDetails.tasks) : {};
  const tasks = tasksByType[TaskType.STANDARD] ?? [];

  const kitchenUseTask = useMemo(() => {
    const kitchenUseActionId = getTaskActionByAutomatedType(AutomatedTaskType.KITCHEN_USE)?.id;

    const taskWithActionId = tasks?.find(
      (task) => task?.dueAt && !task?.completedAt && task.actionId === kitchenUseActionId,
    );

    return taskWithActionId || undefined;
  }, [tasks, getTaskActionByAutomatedType]);

  const breakpoint = useBreakpoint();
  const isSmallScreen = breakpoint === 'xs' || breakpoint === 'sm';

  const { renderEndCleaningConfirmationDialog, onClickEndCleaning } = useEndCleaning({
    queriesToInvalidate: [
      ServerStateKey.DAILY_VIEW_UNITS,
      ServerStateKey.UNITS_CLEANED_TODAY_COUNT,
      ServerStateKey.DAILY_CLEANING_REPORT,
    ],
    onSuccess: () => {
      setUnit(undefined);
    },
    onMarkCleanCancel: () => {
      goToUnitDetailsFn({
        unitId: unit.id,
        propertyId: unit.propertyId,
        expandTasks: true,
      });
      setUnit(undefined);
    },
  });

  const { renderCleaningConfirmationDialog, onClickMarkAsClean } = useMarkAsClean({
    queriesToInvalidate: [
      ServerStateKey.DAILY_VIEW_UNITS,
      ServerStateKey.UNITS_CLEANED_TODAY_COUNT,
      ServerStateKey.DAILY_CLEANING_REPORT,
    ],
    onSuccess: () => {
      setUnit(undefined);
    },
    onMarkCleanCancel: () => {
      goToUnitDetailsFn({
        unitId: unit.id,
        propertyId: unit.propertyId,
        expandTasks: true,
      });
      setUnit(undefined);
    },
  });

  const { renderNoShowReportConfirmationDialog, renderNoShowReportQuestionDialog, onClickNoShowReport } =
    useNoShowReport({
      onSuccess: () => {
        setUnit(undefined);
      },
    });

  const { renderHskDelayQuestionDialog, onClickHskDelay } = useHskDelay({
    property: selectedProperty,
    onSuccess: () => {
      setUnit(undefined);
    },
  });

  const goToUnitDetailsFn = goToUnitDetails(history);

  const openRoomLog = ({ unitNumber, propertyId }: { unitNumber: string; propertyId: string }) => {
    window.open(`${roomLogsUrl}?Unit+Number=${unitNumber}&Property+ID=${propertyId}`, '_blank', 'noopener,noreferrer');
  };

  const startCleaningRequest = useApiCall(unitsApi.startCleaning);
  const startCleaningMutation = useMutation(
    ({ propertyId, id }: { propertyId: string; id: string }) =>
      startCleaningRequest({
        params: { propertyId, id },
      }),
    {
      onSuccess: () => {
        goToUnitDetailsFn({
          unitId: unit.id,
          propertyId: unit.propertyId,
          expandTasks: true,
        });
        setUnit(undefined);
        queryClient.invalidateQueries(ServerStateKey.DAILY_VIEW_UNITS);
      },
    },
  );

  const { renderMoveMidstayCleaningDialog, onClickMoveMidstayClean } = useMoveMidstayClean({
    propertyId: unit.propertyId,
    unitId: unit.id,
    reservationId: unit.reservationId,
    onSuccess: () => {
      goToUnitDetailsFn({
        unitId: unit.id,
        propertyId: unit.propertyId,
      });
      setUnit(undefined);
    },
  });

  const renderDialogContent = useCallback(() => {
    if (!unit) {
      return <div />;
    }
    const { id: unitId, propertyId } = unit;
    return (
      <div className={'flex flex-col py-6 md:pt-6 md:pb-0 px-4 gap-y-6'}>
        {(isOperations || isRoomChecker) && canDelayHskStatus.includes(unit.status) && (
          <ActionItem name={t('hskDelay')} Icon={icons.hskDelay} action={() => onClickHskDelay(unit)} />
        )}
        {canMoveMidstayCleaningStatus.includes(unit.status) && (
          <ActionItem name={t('moveMidstayCleaning')} Icon={ChevronRightIcon} action={onClickMoveMidstayClean} />
        )}
        {unit.tasksCount > 0 && (
          <ActionItem
            name={t('viewTasks')}
            Icon={ClipboardListIcon}
            count={unit.tasksCount}
            action={() => {
              goToUnitDetailsFn({
                unitId,
                propertyId,
                expandTasks: true,
              });
              setUnit(undefined);
            }}
          />
        )}
        {unit.damageReportsCount > 0 && (
          <ActionItem
            name={t('viewDamageReports')}
            Icon={ClipboardListIcon}
            count={unit.damageReportsCount}
            countBadgeColor={'#309bf0'}
            action={() => {
              goToUnitDetailsFn({
                unitId,
                propertyId,
                expandDamageReports: true,
              });
              setUnit(undefined);
            }}
          />
        )}
        {/* eslint-disable-next-line max-len */}
        {(isRoomChecker || isOperations) &&
          unit.status === UnitStatus.VACANT &&
          unit.isNoShow &&
          !unit.noShowReported && (
            <ActionItem
              name={t('noShow')}
              Icon={EyeOffIcon}
              action={() => {
                onClickNoShowReport(unit);
              }}
            />
          )}
        {!isCleanerOnly && (
          <ActionItem
            name={t('addTask')}
            Icon={PlusCircleIcon}
            action={() => {
              goToUnitDetailsFn({
                unitId,
                propertyId,
                expandTasks: true,
                openCreationModal: true,
              });
              setUnit(undefined);
            }}
          />
        )}
        <ActionItem
          name={t('seeDetails')}
          Icon={DotsHorizontalIcon}
          action={() => {
            goToUnitDetailsFn({
              unitId,
              propertyId,
            });
            setUnit(undefined);
          }}
        />
        {isOperations && (
          <ActionItem
            name={t('roomLog')}
            Icon={icons.roomLog}
            action={() => {
              openRoomLog({
                propertyId: propertyExternalId,
                unitNumber: unit.number,
              });
              setUnit(undefined);
            }}
          />
        )}
        <ActionItem
          name={t('addDamageReport')}
          Icon={DocumentTextIcon}
          action={() => {
            if (!unit) return;
            const { id, number } = unit;
            history.push({
              pathname: routes.DAMAGE_REPORT,
              state: { openCreationModal: true, unit: { id, number } },
            });
            setUnit(undefined);
          }}
        />
        <ActionItem
          name={t('addLostAndFound')}
          Icon={BriefcaseIcon}
          action={() => {
            if (!unit) return;
            const { id, number } = unit;
            history.push({
              pathname: routes.LOST_AND_FOUND,
              state: { openCreationModal: true, unit: { id, number } },
            });
            setUnit(undefined);
          }}
        />
        {canMarkForInspectionUnitStatuses.includes(unit.status) &&
          // Start Cleaning button is shown for cleaners and room checkers
          (isCleaner || isRoomChecker) &&
          !isOperations &&
          !unit.currentCleaningId && (
            <button
              className={`w-full py-4 text-white text-base font-normal font-sans rounded-md bg-th-secondary`}
              onClick={() => {
                if (!startCleaningMutation.isLoading && !kitchenUseTask) {
                  startCleaningMutation.mutate({
                    id: unit.id,
                    propertyId: unit.propertyId,
                  });
                } else if (kitchenUseTask) {
                  setIsKitchenUseModalOpen(true);
                }
              }}
            >
              {t('startCleaning')}
            </button>
          )}
        {canMarkForInspectionUnitStatuses.includes(unit.status) &&
          ((isCleaner && unit.currentCleaningByMe) || isRoomChecker || isOperations) &&
          unit.currentCleaningId && (
            <button
              className={`w-full py-4 text-white text-base font-normal font-sans rounded-md bg-th-secondary`}
              onClick={onClickEndCleaning(unit)}
            >
              {isOperations ? t('markAsClean') : t('markAsReadyToInspect')}
            </button>
          )}
        {canMarkAsCleanUnitStatuses.includes(unit.status) &&
          (isRoomChecker || isOperations) &&
          !unit.currentCleaningId && (
            <button
              className={`w-full py-4 text-white text-base font-normal font-sans rounded-md bg-th-secondary`}
              onClick={onClickMarkAsClean(unit)}
            >
              {t('markAsClean')}
            </button>
          )}
      </div>
    );
  }, [
    unit,
    isCleaner,
    isRoomChecker,
    startCleaningMutation,
    onClickEndCleaning,
    onClickMarkAsClean,
    onClickMoveMidstayClean,
  ]);

  const unitTimeText = useCallback(
    (u: UnitInfoType) => {
      if (u.arrival) {
        const time = formatDate(u.arrival, TIME_FORMAT);
        return u.earlyCheckIn ? `${t('eci')}: ${time}` : time;
      }
      if (u.departure) {
        const time = formatDate(u.departure, TIME_FORMAT);
        return u.lateCheckOut ? `${t('lco')}: ${time}` : time;
      }
      return '';
    },
    [t],
  );

  const startCleaning = useCallback(() => {
    if (!startCleaningMutation.isLoading) {
      startCleaningMutation.mutate({
        id: unit.id,
        propertyId: unit.propertyId,
      });
    }
  }, [startCleaningMutation]);

  const renderConfirmationDialogs = useCallback(() => {
    return (
      <>
        {renderEndCleaningConfirmationDialog()}
        {renderCleaningConfirmationDialog()}
        {renderNoShowReportConfirmationDialog()}
        {renderNoShowReportQuestionDialog()}
        {renderHskDelayQuestionDialog()}
      </>
    );
  }, [renderEndCleaningConfirmationDialog, renderCleaningConfirmationDialog, renderNoShowReportConfirmationDialog]);

  // todo: check if this is correct way to stop glitching when isOpen condition in Dialog is !!unit
  if (!unit) return null;

  if (isKitchenUseModalOpen && kitchenUseTask) {
    return (
      <KitchenUseTaskModal
        unitId={unit.id}
        saveButtonLabel={t('savePicturesAndStartCleaning')}
        startCleaning={startCleaning}
        kitchenUseTask={kitchenUseTask}
        onClose={() => setIsKitchenUseModalOpen(false)}
      />
    );
  }

  return (
    <>
      <Dialog
        isOpen
        onClose={() => setUnit(undefined)}
        customHeader={
          <div className="w-full flex md:flex-row-reverse justify-between items-center px-4 pt-4 md:pt-3 pb-3 border-b border-b-[#DEDEDE]">
            <button
              type="button"
              className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
              onClick={() => setUnit(undefined)}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div
              className={
                'flex flex-row items-center justify-end md:justify-start space-x-2 pl-4 md:pl-0 flex-1 md:w-80'
              }
            >
              <IconWithText Icon={icons.door} text={unit.number || ''} large />
              {(unit.arrival || unit.departure) && <IconWithText Icon={ClockIcon} text={unitTimeText(unit)} large />}
              {unit.isVip && <IconWithText Icon={StarIcon} text={'VIP'} large />}
            </div>
          </div>
        }
        isMobileSheet
      >
        <>
          {renderDialogContent()}
          {!isSmallScreen && renderMoveMidstayCleaningDialog()}
          {!isSmallScreen && renderConfirmationDialogs()}
        </>
      </Dialog>
      {isSmallScreen && renderMoveMidstayCleaningDialog()}
      {isSmallScreen && renderConfirmationDialogs()}
    </>
  );
}
