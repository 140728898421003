import { UnitStatus } from '@typings/enums';
import { ThinArea, ThinUnit, UnitInfoType, UserRole } from '@typings/types';

export const canMarkAsCleanUnitStatuses = [
  UnitStatus.READY_TO_CLEAN,
  UnitStatus.STAYOVER_READY_TO_CLEAN,
  UnitStatus.READY_TO_INSPECT,
  UnitStatus.STAYOVER_READY_TO_INSPECT,
];

export const canMarkForInspectionUnitStatuses = [UnitStatus.READY_TO_CLEAN, UnitStatus.STAYOVER_READY_TO_CLEAN];

export const canMoveMidstayCleaningStatus = [UnitStatus.STAYOVER_READY_TO_CLEAN];

export const canDelayHskStatus = [UnitStatus.READY_TO_CLEAN];

const dailyViewUnitStatuses = [
  UnitStatus.READY_TO_CLEAN,
  UnitStatus.STAYOVER_READY_TO_CLEAN,
  UnitStatus.READY_TO_INSPECT,
  UnitStatus.STAYOVER_READY_TO_INSPECT,
  UnitStatus.OCCUPIED_CLEANING_TODAY,
  UnitStatus.OCCUPIED,
  UnitStatus.VACANT,
];

export const expandedDailyViewUnitStatuses = dailyViewUnitStatuses.slice(0, 4);

export const collapsedDailyViewUnitStatuses = dailyViewUnitStatuses.slice(4);

export const dailyViewUnitStatusesWithRequiredRoles: { [key: string]: UserRole[] } = {
  [UnitStatus.READY_TO_CLEAN]: [UserRole.CLEANER, UserRole.OPERATIONS, UserRole.ICT, UserRole.ROOMCHECKER],
  [UnitStatus.STAYOVER_READY_TO_CLEAN]: [UserRole.CLEANER, UserRole.OPERATIONS, UserRole.ROOMCHECKER],
  [UnitStatus.OCCUPIED_CLEANING_TODAY]: [UserRole.CLEANER, UserRole.ROOMCHECKER, UserRole.OPERATIONS, UserRole.ICT],
  [UnitStatus.READY_TO_INSPECT]: [UserRole.ROOMCHECKER, UserRole.OPERATIONS, UserRole.ICT],
  [UnitStatus.STAYOVER_READY_TO_INSPECT]: [UserRole.ROOMCHECKER, UserRole.OPERATIONS],
  [UnitStatus.OCCUPIED]: [UserRole.OPERATIONS, UserRole.ICT],
  [UnitStatus.VACANT]: [UserRole.OPERATIONS, UserRole.ROOMCHECKER, UserRole.ICT],
};

export const maintainerDailyViewUnitStatuses = [
  UnitStatus.READY_TO_CLEAN,
  UnitStatus.READY_TO_INSPECT,
  UnitStatus.OCCUPIED_CLEANING_TODAY,
  UnitStatus.OCCUPIED,
  UnitStatus.VACANT,
];

export const isUnitType = (item: ThinArea | ThinUnit) => 'number' in item;

export const openReservationInApaleo = (propertyId: string, reservationId: string) => {
  window.open(`https://app.apaleo.com/${propertyId}/reservations/${reservationId}`, '_blank');
};

export const OPEN_TASKS_SECTION_KEY = 'OPEN_TASKS';

export const groupingAndSortingUnitsByCheckInTime = (units: UnitInfoType[]) => {
  const earlyCheckInUnits: Record<string, UnitInfoType[]> = {};
  const earlyCheckInUnitsKeys: number[] = [];
  const standardUnits: UnitInfoType[] = [];

  units.forEach((u) => {
    if (u.earlyCheckIn) {
      const arrival = u?.arrival ? new Date(u.arrival).getHours() : null;
      if (arrival) {
        if (!earlyCheckInUnits[arrival]) {
          earlyCheckInUnits[arrival] = [];
          earlyCheckInUnitsKeys.push(arrival);
        }
        earlyCheckInUnits[arrival].push(u);
      }
    } else {
      standardUnits.push(u);
    }
  });
  const sortedEarlyCheckInUnitsKeys = earlyCheckInUnitsKeys.sort((a, b) => a - b);
  return { earlyCheckInUnits, standardUnits, sortedEarlyCheckInUnitsKeys };
};
