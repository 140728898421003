import { startOfDay, isToday, getTime, isSameMonth, addDays } from 'date-fns';
import Table, { SidebarHeader, TimelineHeaders, TimelineKeys } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import './styles/RoomRackTableOverride.css';

import { Slot } from './components/Slot';
import { ColumnHeader } from './components/ColumnHeader';
import { RowHeader } from './components/RowHeader';

import { getDateFnsCurrentLocale } from '@utils/getDateFnsCurrentLocale';
import { RowType, SlotItem, RoomRackTableProps } from './types';
import { getThreeLettersAbbrevMonth } from '@utils/dateUtils';

const keys: TimelineKeys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'data',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start_time',
  itemTimeEndKey: 'end_time',
};

export const RoomRackTable = ({ onSlotClick, roomsData, slotsData, dateRange }: RoomRackTableProps) => {
  const { start: startDate, end: endDate } = dateRange;
  const dateFnsLocale = getDateFnsCurrentLocale();

  return (
    <Table<SlotItem, RowType>
      groups={roomsData}
      items={slotsData}
      stackItems={true}
      itemRenderer={(itemProps) => <Slot {...itemProps} />}
      onItemSelect={(itemId) => onSlotClick(itemId)}
      canMove={false}
      canResize={false}
      canChangeGroup={false}
      sidebarWidth={56}
      lineHeight={56}
      visibleTimeStart={getTime(startOfDay(startDate))}
      visibleTimeEnd={getTime(startOfDay(addDays(endDate, 1)))}
      buffer={1}
      keys={keys}
      verticalLineClassNamesForTime={(date) => {
        return isToday(date) ? ['vertical-line-today'] : [''];
      }}
      groupRenderer={RowHeader}
    >
      <TimelineHeaders>
        <SidebarHeader>
          {({ getRootProps }) => {
            return (
              <div
                className="bg-transparent flex justify-center items-center font-sans text-xs font-semibold"
                {...getRootProps()}
              >
                <span className="capitalize">{getThreeLettersAbbrevMonth(startDate, dateFnsLocale)}</span>
                {!isSameMonth(startDate, endDate) && (
                  <>
                    <span className="mx-[2px]">-</span>
                    <span className="capitalize">{getThreeLettersAbbrevMonth(endDate, dateFnsLocale)}</span>
                  </>
                )}
              </div>
            );
          }}
        </SidebarHeader>
        <ColumnHeader />
      </TimelineHeaders>
    </Table>
  );
};
