interface TimeSelectorProps {
  label: string;
  value: string | null;
  onChangeTime: (time: string) => void;
  error?: string;
}

export const TimeSelector = ({ label, value, onChangeTime, error }: TimeSelectorProps) => {
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'hour' | 'minute') => {
    const newValue = e.target.value;

    const newValueNumeric = Number(newValue);
    if (isNaN(newValueNumeric)) return;

    const [hours, minutes] = value ? value.split(':') : ['0', '0'];

    if (type === 'hour') {
      if (newValueNumeric < 0 || newValueNumeric > 23) return;
      onChangeTime(`${newValue}:${minutes}`);
    }

    if (type === 'minute') {
      if (newValueNumeric < 0 || newValueNumeric > 59) return;
      onChangeTime(`${hours}:${newValue}`);
    }
  };

  return (
    <div className="relative w-2/5 font-sans">
      <p className={`${error ? 'text-th-red-300' : 'text-th-brown-100'} text-xs font-normal leading-4 w-full`}>
        {label}
      </p>
      <div
        className={`flex justify-start items-center border-b ${
          error ? 'border-th-red-300' : 'border-th-brown-50'
        } pb-1 ${value ? 'text-th-brown' : 'text-th-brown-100'}`}
      >
        <input
          type="text"
          value={value ? value.split(':')[0] : ''}
          onChange={(e) => handleTimeChange(e, 'hour')}
          placeholder="0"
          className={`p-0 border-0 bg-transparent w-6 focus:outline-none focus:ring-0 text-center text-th-brown placeholder-th-brown-100`}
          maxLength={2}
        />
        :
        <input
          type="text"
          value={value ? value.split(':')[1] : ''}
          onChange={(e) => handleTimeChange(e, 'minute')}
          placeholder="0"
          className={`p-0 border-0 bg-transparent w-6 focus:outline-none focus:ring-0 text-center text-th-brown placeholder-th-brown-100`}
          maxLength={2}
        />
      </div>
    </div>
  );
};
