import { useState } from 'react';
import { format, Locale } from 'date-fns';
import DatePicker from 'react-datepicker';
import { Popover, Portal } from '@headlessui/react';
import { usePopper } from 'react-popper';

import Dialog from '@molecules/Dialog';
import useBreakpoint from '@utils/hooks/useBreakpoint';
import { getDateFnsCurrentLocale } from '@utils/getDateFnsCurrentLocale';

interface DateSelectorProps {
  label: string;
  value: Date | null;
  minDate: Date;
  maxDate: Date;
  onChangeDate: (date: Date) => void;
  error?: string;
}

interface DatePickerProps {
  value: Date | null;
  locale: Locale;
  minDate: Date;
  maxDate: Date;
  onChangeDate: (date: Date) => void;
  onClickOutside?: () => void;
}

const DatePickerComponent = ({ value, locale, minDate, maxDate, onChangeDate, onClickOutside }: DatePickerProps) => {
  return (
    <DatePicker
      locale={locale}
      autoComplete="off"
      selected={value}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChangeDate}
      highlightDates={[{ 'highlight-day': value ? [value] : [] }]}
      disabledKeyboardNavigation
      inline
      onClickOutside={onClickOutside}
    />
  );
};

export const DateSelector = ({ label, value, minDate, maxDate, onChangeDate, error }: DateSelectorProps) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null);

  const dateFnsLocale = getDateFnsCurrentLocale();
  const breakpoint = useBreakpoint();
  const isSmallScreen = breakpoint === 'xs' || breakpoint === 'sm';

  const isActive = !!value;

  const handleDateChange = (date: Date) => {
    if (date) {
      onChangeDate(date);
      setIsCalendarOpen(false);
    }
  };

  const { styles, attributes } = usePopper(buttonRef, popupRef, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [16, 0] } }],
  });

  return (
    <Popover className="relative w-3/5 font-sans">
      <p className={`${error ? 'text-th-red-300' : 'text-th-brown-100'} text-xs font-normal leading-4 w-full`}>
        {label}
      </p>
      <Popover.Button
        type="button"
        ref={setButtonRef}
        className={`w-full text-left pb-1 border-b ${isActive ? 'text-th-brown' : 'text-th-brown-100'} ${
          error ? 'border-th-red-300' : 'border-th-brown-50'
        }`}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setIsCalendarOpen(!isCalendarOpen);
        }}
      >
        {isActive ? format(value, 'dd.MM.yyyy') : 'DD.MM.YYYY'}
      </Popover.Button>
      {isSmallScreen ? (
        <Dialog
          isOpen={isCalendarOpen}
          onClose={() => {
            setIsCalendarOpen(false);
          }}
        >
          <DatePickerComponent
            locale={dateFnsLocale}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            onChangeDate={handleDateChange}
          />
        </Dialog>
      ) : (
        isCalendarOpen && (
          <Portal>
            <Popover.Panel static ref={setPopupRef} style={styles.popper} {...attributes.popper}>
              <DatePickerComponent
                locale={dateFnsLocale}
                value={value}
                minDate={minDate}
                maxDate={maxDate}
                onChangeDate={handleDateChange}
                onClickOutside={() => setIsCalendarOpen(false)}
              />
            </Popover.Panel>
          </Portal>
        )
      )}
    </Popover>
  );
};
