import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid';

import Dialog from '@molecules/Dialog';
import Loader from '@molecules/Loader';
import { Selector } from './Selector';
import { SlotItem } from '@organisms/RoomRack/RoomRackTable/types';
import useDeleteSlotRoomRack from '@utils/hooks/useDeleteRoomRack';

import icons from '@constants/icons';

type DeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  slotItem: SlotItem;
  selectedProperty: { id: string; timeZone: string };
  dateRange: { start: Date; end: Date };
};

export const DeleteModal = ({ isOpen, onClose, slotItem, selectedProperty, dateRange }: DeleteModalProps) => {
  const { t } = useTranslation();
  const [deleteReason, setDeleteReason] = useState('');
  const [isErrorDeleting, setErrorDeleting] = useState<boolean>(false);
  const [errorSubmitting, setErrorSubmitting] = useState<string>('');

  const deleteReasonsOptions = [
    { label: t('issueResolveDeleteReason'), value: 'issueResolved' },
    { label: t('createdByMistakeDeleteReason'), value: 'createdByMistake' },
    { label: t('guestRejectedMoveDeleteReason'), value: 'guestRejectedRoomMove' },
    { label: t('guestAcceptedMoveDeleteReason'), value: 'guestAcceptedRoomMove' },
  ];

  const { submitDeleteSlotForm, isSubmitting } = useDeleteSlotRoomRack({
    property: selectedProperty,
    dateRange,
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      setErrorDeleting(true);
    },
  });

  const handleDeleteSlot = async () => {
    if (deleteReason === '') return setErrorSubmitting(t('deleteReasonIsRequired'));
    await submitDeleteSlotForm(slotItem.id as string, { reason: deleteReason });
  };

  const handleDeleteReason = (reason: string) => {
    setErrorSubmitting('');
    setDeleteReason(reason);
  };

  if (isErrorDeleting) {
    return (
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        customHeader={
          <div className="flex justify-between items-center font-serif text-th-brown text-base font-bold px-4 pb-4 pt-1 border-b border-[#D9D9D9]">
            <button onClick={onClose}>
              <XIcon className="w-6 h-6 text-th-secondary" />
            </button>
            <h2>{t('errorDeleteSlot')}</h2>
            <div />
          </div>
        }
      >
        <div className="flex flex-col justify-center items-center w-[360px] font-serif pt-1">
          <div className="text-sm leading-[18px] font-normal font-sans text-th-secondary px-7 py-4">
            {t('errorDeleteInfoSlot')}
          </div>
          <div className="px-5 pt-4 w-full">
            <button
              onClick={onClose}
              className="py-4 bg-th-secondary rounded-md text-base font-normal font-sans text-white w-full"
            >
              OK
            </button>
          </div>
        </div>
      </Dialog>
    );
  }

  if (isSubmitting) {
    return (
      <Dialog isOpen={isOpen} onClose={onClose} customHeader={<></>}>
        <div className="flex flex-col justify-center items-center min-w-[284px] gap-y-4 font-serif pt-1">
          <div className="text-lg font-bold leading-6 text-th-brown">{t('deleting')}</div>
          <Loader />
          <div className="text-sm leading-[18px] font-normal font-sans text-th-secondary">
            {t('pleaseWaitAFewSeconds')}
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      customHeader={
        <div className="flex justify-between items-center font-serif text-th-brown text-base font-bold px-4 pb-4 pt-1 border-b border-[#D9D9D9]">
          <button onClick={onClose}>
            <XIcon className="w-6 h-6 text-th-secondary" />
          </button>
          <h2>{t('deleteSlot')}</h2>
          <div className="flex justify-center items-center">
            <icons.roomFilter className="w-5 h-5" /> {slotItem.roomNumber}
          </div>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="max-w-[360px] font-sans">
        <div className="p-4 text-sm leading-[18px]">
          <p className="font-semibold">{t('deleteSlotLabel')}</p>
          <div className="mt-2">
            <Selector
              optionsClassName="w-auto"
              options={deleteReasonsOptions}
              onChange={handleDeleteReason}
              value={deleteReason}
              error={errorSubmitting}
            />
          </div>
        </div>
        <div className="py-4 px-5">
          <button
            className="w-full py-4 rounded-md text-white bg-th-secondary text-base font-normal"
            onClick={handleDeleteSlot}
          >
            {t('deleteSlot')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
