import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Ventilator u kupatilu glasan',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Svetlo ne radi',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Sudopera je blokirana',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Odvod tuša je blokiran',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filter za kupatilo',
  [StandardizedDamageReport.SINK]: 'Sudoper',
  [StandardizedDamageReport.TOILET]: 'Toalet',
  [StandardizedDamageReport.SHOWER]: 'Tuš',
  [StandardizedDamageReport.STOVE]: 'Peć',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Frižider/minibar',
  [StandardizedDamageReport.DOOR]: 'Vrata',
  [StandardizedDamageReport.WINDOW]: 'Prozor',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Sijalica',
  [StandardizedDamageReport.CHAIRS]: 'Stolice',
  [StandardizedDamageReport.HEATING]: 'Grijanje',
  [StandardizedDamageReport.TV]: 'TV',
  [StandardizedDamageReport.TABLES]: 'Stolovi',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Tim za čišćenje',
  [Team.MAINTENANCE]: 'Tim za popravke',
  [Team.OPERATIONS]: 'Operativni tim',
  [Team.ICT]: 'ICT',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Kuhinja',
  [DamageReportArea.BED]: 'Krevet',
  [DamageReportArea.BATHROOM]: 'Kupatilo',
  [DamageReportArea.SEATING_AREA]: 'Prostor za sedenje',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Zidovi/Strop/Pod',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Očisti',
  [DamageReportActionRequired.REPLACE]: 'Zamijenite',
  [DamageReportActionRequired.REPAIR]: 'Popravi',
  [DamageReportActionRequired.CHECK]: 'Proveri',
  [DamageReportActionRequired.OTHER]: 'Ostalo',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Standardan',
  [TaskPriority.HIGH]: 'Visok',
};

const popupNotifications = {
  taskDeletionNotification: 'Zadatak je uspešno izbrisan',
  lostAndFoundDeletionNotification: 'Izgubljena i pronađena stavka je uspešno izbrisana',
  damageReportDeletionNotification: 'Izveštaj o oštećenju je uspešno izbrisan',
  taskCreationNotification: 'Zadatak je uspešno kreiran',
  lostAndFoundCreationNotification: 'Izgubljena i pronađena stavka je uspešno kreirana',
  damageReportCreationNotification: 'Izveštaj o šteti je uspešno kreiran',
  taskCompletionNotification: 'Zadatak je uspešno završen',
  lostAndFoundCompletionNotification: 'Izgubljena i pronađena stavka je uspešno rešena',
  damageReportCompletionNotification: 'Izveštaj o šteti je uspešno rešen',
  taskUpdateNotification: 'Zadatak je uspešno ažuriran',
  lostAndFoundUpdateNotification: 'Izgubljena i pronađena stavka je uspešno ažurirana',
  damageReportUpdateNotification: 'Izveštaj o šteti je uspešno ažuriran',
  pleaseSelectRoomsNotification: 'Molimo vas da odaberete sobe',
  noShowReportNotification: 'Za sobu {{number}} nije prijavljena nijedna emisija',
  checkInReportNotification: 'Prijava je uspješno prijavljena za sobu {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Izveštaj o šteti zakazan za {{date}}',
  hskDelayNotification: 'Uspešno HSK odlaganje za sobu {{number}}. Check in pomeren za {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Više filtera',
  resetAllFilters: 'Поништи све филтере',
  reset: 'Ponovno podešavanje',
  cleaned: 'Očišćeno',
  allRoomsCleaned: `Sve sobe su očišćene`,
  dirty: 'Prljavo',
  readyToClean: 'Spreman za čišćenje',
  stayoverReadyToClean: 'Prenoćenje - spremno za čišćenje',
  occupiedCleaningToday: 'Zauzete sobe - čišćenje danas',
  readyToInspect: 'Spremno za kontrolu',
  stayoverInspection: 'Prenoćenja za kontrolu',
  vacant: 'Slobodne sobe',
  occupied: 'Zauzete',
  clean: 'Čist',
  daily: 'Dnevni',
  weekly: 'Nedeljni',
  biweekly: 'Bi-Veekli',
  monthly: 'Mesečno',
  none: 'nijedan',
  tasks: 'Zadaci',
  notifications: 'Obaveštenja',
  more: 'Više',
  select: 'Izaberite',
  property: 'Lokacija',
  result: 'Rezultat',
  results: 'Rezultati',
  properties: 'Posedi',
  rooms: 'Sobe',
  team: 'Tim',
  dueDate: 'Rok predaje',
  date: 'Datum',
  close: 'Zatvori',
  search: 'Pretraga',
  login: 'Prijavite se',
  loading: 'Učitava se',
  anErrorHasOccurred: 'Došlo je do greške',
  chooseDate: 'Izaberi datum',
  selectDate: 'Izaberi datum',
  selectADate: 'Izaberi datum',
  markAsClean: 'Označi kao čisto',
  markAsReadyToInspect: 'Označi kao spremno za kontrolu',
  viewTasks: 'Pregledaj zadatke',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Evidencija soba`,
  taskLog: `Dnevnik zadataka`,
  seeDetails: 'Vidi detalje',
  assignedTasks: 'Dodeljeni zadaci',
  delete: 'Obriši',
  deleteTask: 'Obriši Zadatak',
  deleteTaskConfirmationMessage: 'Jeste li sigurni da želite da izbrišete ovaj zadatak?',
  deleteTaskRepeatsConfirmationMessage:
    'Ovo se ponavlja zadatak. Možete izbrisati samo ovaj ili ponavljajuće buduće zadatke',
  deleteOnlyThisTask: 'Izbriši samo ovaj zadatak',
  deleteRepeatingTask: 'Izbriši ponavljajući zadatak',
  yes: 'da',
  no: 'Ne',
  pleaseWaitAFewSeconds: 'Sačekajte nekoliko sekundi',
  deleting: 'Brisanje',
  lostAndFound: 'Izgubljeno i nađeno',
  current: 'Trenutni',
  archive: 'Arhiva',
  edit: 'Izmeni',
  markAsResolved: 'Označi kao završeno',
  markAsUnresolved: 'Označi kao nezavršeno',
  add: 'Dodati',
  area: 'Area',
  addTask: 'Dodaj zadatak',
  editTask: 'Izmeni zadatak',
  seeTaskDetails: 'Vidi zadatak',
  savePicturesAndStartCleaning: 'Сачувајте и почните чишћење',
  savePictures: 'Сачувај слике',
  takePhoto: 'Napravite fotografiju',
  addArea: 'Dodaj deo/prostoriju hotela',
  addAreaDescription: 'Dodaj deo/prostoriju hotela za ovaj zadatak',
  selectProperty: 'Izaberi nekretninu',
  selectRooms: 'Izaberi sobe',
  selectTeam: 'Izaberi tim',
  selectDueDate: 'Izaberi datum',
  hskTeam: 'HSK Tim',
  save: 'Sačuvaj',
  actionIsRequired: `Potrebna je akcija. Obrišite naslov i izaberite akciju sa liste.`,
  noActionSelected: `Izabrana akcija nije validna`,
  changeSearchAction: `Pokušajte promeniti termin pretrage`,
  noActionFound: `Nema pronađene radnje`,
  selectActionHelp: `Ne možete pronaći radnju? Molimo vas kontaktirajte tim za operacije kako bi je mogli zatražiti.`,
  writeToSelectAction: 'Пишите да изаберете акцију',
  writeTask: 'Opiši zadatak',
  repetition: 'Понављање',
  noRepetition: 'Нема понављања',
  repetitionTime: 'Vreme ponavljanja',
  doesNotRepeat: 'Ne ponavlja se',
  day: 'Dan',
  week: 'Nedelja',
  month: 'Mesec dana',
  repeatOn: 'Ponavlja se na',
  repeatsEvery: 'Ponavlja se svaki',
  description: 'Opis',
  title: 'Naslov',
  addTitle: 'Dodaj naslov',
  titleIsRequired: 'Naslov je obavezan',
  titleMaxLength: 'Naslov mora biti kraći od {{maxLength}} karaktera',
  teamIsRequired: 'Tim je obavezan',
  openTasks: 'Preostali zadaci',
  arrival: 'Dolazak',
  departure: 'Odlazak',
  checkIn: 'Vreme ulaska',
  times: 'Vremena',
  guests: 'Gosti',
  name: 'Ime',
  additionalInfo: 'Dodatne informacije',
  addLostAndFound: 'Dodaj izgubljeno i pronađeno',
  addDamageReport: 'Dodaj izveštaj o šteti',
  unitIsRequired: 'Jedinica je potrebna',
  addItem: 'Dodajte stavku',
  editItem: 'Izmeni stavku',
  startCleaning: 'Počni sa čišćenjem',
  endCleaning: 'Završi čišćenje',
  endCleaningMessage: 'Postoji 1 nezavršen zadatak. Jeste li sigurni da želite završiti čišćenje?',
  endCleaningMessage_plural: 'Postoji {{count}} nezavršenih zadataka. Jeste li sigurni da želite završiti čišćenje?',
  cancelAndViewTasks: 'Otkažite i pregledajte zadatke',
  ignoreAndMarkAsReadyToInspect: 'Zanemari i označi kao spremno za pregled',
  ignoreAndMarkAsCleaned: 'Zanemari i označi kao očišćeno',
  damageReports: 'Izveštaji o šteti',
  confirm: 'Potvrdi',
  selectRoomOrArea: 'Odaberi sobu/područje ili',
  createNewOne: 'stvoriti novu',
  today: 'Danas',
  tomorrow: 'Sutra',
  noTasksMessage: 'Nijedan zadatak nije dodat',
  cleaningStatus: 'Čišćenje',
  logOut: 'Odjavi se',
  language: 'Jezik',
  selectLanguage: 'Izaberi jezik',
  isStandardized: 'Da li je standardizovano?',
  selectStandardizedTask: 'Izaberi standardizovani zadatak',
  overdue: 'Sa kašnjenjem',
  arrivals: 'Dolasci',
  memberArrivals: 'Member arrivals',
  departures: 'Odlasci',
  stayovers: 'Broj prenoćenja',
  occupancy: 'OTB Zauzetost',
  midstayCleaning: 'Čišćenje prilikom prenoćenja',
  roomsToSell: 'Sobe za prodaju',
  editImages: 'Uređivanje slika',
  toClean: 'čistiti',
  toInspect: 'za kontrolu',
  noRoomsForCleaningToday: 'Danas nema soba za čišćenje',
  noShow: 'Nije se pojavio',
  noShowReportConfirmationMessage: 'Potvrdite da se broj sobe {{number}} ne prikazuje.',
  noShowReportQuestion: 'Da li se gost pojavio juče?',
  checkInReportConfirmationMessage: 'Potvrdite da se gost check in-ovao u sobu {{number}}',
  noResultsSearchMessage: 'Nijedan rezultat ne odgovara vašim kriterijumima pretrage',
  cancel: 'Prekini',
  selectCleaner: 'Izaberi osobu',
  selectAll: 'Izaberi sve',
  floor: 'Sprat',
  notAssigned: 'Ne dodeljen',
  assignXRooms: 'Dodeli {{total}} soba',
  assignRooms: 'Dodeli sobe',
  approvedLCOs: 'Ddobreni LCO-ovi',
  cleaner: 'Čisti',
  roomsCleanForToday: 'Sve sobe su očišćene! 🎉🎉',
  viewDamageReports: 'Pogledaj prijavljenu štetu',
  noTasks: 'Nema preostalih zadataka',
  noDamageReports: 'Nema prijavljene štete',
  dailyCleaningReport: 'Izveštaj dnevnog čišćenja',
  stayover: 'Prenoćenje',
  roomMoveFrom: 'Premeštaj iz sobe {{value}}',
  roomMoveTo: 'Premeštaj u sobu {{value}}',
  unassignAll: 'Ukloni {{total}} dodela',
  unassignRoomsConfirmationMessage: 'Da li ste sigurni da želite da uklonite {{total}} dodeljene sobe za čišćenje?',
  unassignRoomsTitle: 'Ukloni dodeljene sobe za čišćenje',
  noRoomAssigned: 'Nema dodeljenih soba',
  action: 'Radnja',
  hideActions: 'Sakrij akcije',
  showActions: 'Prikaži radnje',
  actionsSelected: '{{total}} izabrana radnja(radnje)',
  disableActionTitleModal: 'Resetujte filter {{name}}',
  disableActionDescriptionModal: 'Za korišćenje filtera {{currentFilter}}, molimo resetujte filter {{resetFilter}}.',
  actionRequired: 'Potrebna radnja',
  priority: 'Prioritet',
  lcoUntil: 'LCO do',
  created: 'Kreiran',
  startNow: 'Start now',
  scheduleTime: 'Schedule time',
  viewInfo: 'View info',
  reminder: 'Podsetnik',
  reminderTaskNotificationText: 'Your scheduled task is due today',
  inProgress: 'In progress',
  scheduled: 'Scheduled',
  moveMidstayCleaning: 'Pomeri čišćenje?',
  moveMidstayTitle: 'Pomeri čišćenje',
  moveMidstayDescription: 'Da li ste sigurni da želite da pomerite čišćenje za {{date}}?',
  moveMidstayNotPossible: 'Nije moguće pomeriti čišćenje za drugi dan u ovoj sobi!',
  moveMidstayNotification: 'Uspešno ste pomerili čišćenje za {{date}}.',
  pleaseContactOps: 'Kontaktirajte Operativni tim za opciju pomeranja čišćenja',
  midCleanMovedTo: 'Čišćenje pomereno za: {{value}}',
  optional: 'Neobavezno',
  hskDelay: 'HSK odlaganje',
  hskDelays: 'HSK odlaganja',
  hskDelayMessage: 'Promeri check in za sobu {{number}}',
  notStarted: 'Nije počelo',
  resolved: 'Završeno',
  noDamageReportsToday: 'Nema izveštaja o šteti za danas',
  yourTask: 'Vaš zadatak',
  yourTaskGX: 'Vaš zadatak od GX-a',
  damageReportedByGX: 'Štetu prijavio GX',
  stay: 'Datumi boravka',
  checkOut: 'Vreme odjave',
  noPermission: 'Nemate dozvolu, molimo kontaktirajte podršku.',
  approvedCheckoutTime: 'Odobreno vreme odjave',
  requestedCheckoutTime: 'Zatraženo vreme odjave',
  checkinTime: 'Vreme prijave',
  commentForHousekeeping: 'Broj odobrenih LCO-ova',
  numberOfApprovedLCOs: 'Broj odobrenih LCO-ova',
  notSet: 'Nije postavljeno',
  vip: 'VIP',
  extraService: 'Dodatna usluga',
  reservationMissing: 'Rezervacija je najverovatnije otkazana, prebacite se na opšti tab umesto toga',
  confirmFor: 'Potvrdi za {{date}}',
  confirmAnotherDay: 'Potvrdi za idući dan',
  taskConfirmed: 'Potvrdili ste zadatak za {{date}}',
  confirmYourTask: 'Potvrdite vaš zadatak',
  confirmYourTaskGX: 'Potvrdite vaš zadatak iz GX-a',
  confirmHighPriorityTaskMessage:
    'Da li ste sigurni da zelite da postavite krajnji datum završetka zadatak za danas? velika je verovatnoća da on neće biti danas potvrđen niti završen.',
  setTomorrow: 'Postavi za sutra',
  keepToday: 'Zadrži za danas',
  createTask: 'Kreiraj zadatak',
  confirmationNeeded: 'Sa zahtevom za potvrdu',
  pictureTooBlurry: `Slika je previše mutna. Molimo vas da uslikate jasnu fotografiju i postavite je.`,
  tryAgain: `Pokušaj ponovo`,
  addDescription: `Dodaj opis`,
  togglePushNotificationsLabel: `Obaveštenja o guranju za iOS`,
  roomRack: `Sobe`,
  newSlot: `Нов слот`,
  searchByRoomNumber: `Број собе`,
  reservation: `Rezervacija`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Тип слота`,
  selectType: `Izaberite tip`,
  reason: `Razlog`,
  selectReason: `Izaberite razlog`,
  selectDateAndTime: `Datum i vreme`,
  startDate: `Datum početka`,
  startTime: `Vreme početka`,
  endDate: `Krajnji datum`,
  endTime: `Krajnje vreme`,
  room: `Soba`,
  selectRoom: `Izaberite sobu`,
  cleanAfterBlock: `Čišćenje potrebno nakon slota`,
  addSlot: `Додајте слот`,
  updateSlot: `Ažurirajte slot`,
  errorStartDatePastSlotForm: `Datum početka ne može biti u prošlosti`,
  errorEndDatePastSlotForm: `Krajnji datum ne može biti pre početnog datuma`,
  errorEndTimePastSlotForm: `Krajnje vreme ne može biti pre početnog vremena`,
  errorMinDurationSlotForm: `Минимално трајање је 30 мин`,
  errorOverbookedSlotForm: `Slot može dovesti do prekomernog rezervisanja za odabrani dan(e). Napravite ga samo ako je zaista potrebno, inače odaberite drugi vremenski period.`,
  outOfOrderInfo: `OOO - Out of Order. Treba koristiti kada soba nije funkcionalna i ne može se koristiti od strane gosta.`,
  outOfServiceInfo: `OOS - Out of Service. Omogućava prodaju sobe. Soba označena kao Out of Service je potpuno funkcionalna i ima samo manje kozmetičke nedostatke.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Koristi ga tim Launch za dugotrajne planove održavanja, kao što je renoviranje celog sprata.`,
  maintenanceReason: `Održavanje`,
  cleaningIssueReason: `Pitanje čišćenja`,
  roomMoveBlockReason: `Soba/hotel premestiti`,
  bedBugsReason: `Bubašvabe u krevetu`,
  smokingReason: `Pušenje`,
  vipGuestReason: `Gost VIP-a`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: стратешки`,
  rampUpRnrReason: `Ramp-up: соба није спремна`,
  hoReason: `Оптимизација HSK`,
  othersReason: `Drugi razlog`,
  createdInApaleo: `Napravljeno u Apaleu`,
  reasonNotApaleo: `\`Napravljeno u Apaleu\` nije validan razlog. Molimo izaberite drugi.`,
  quarantineReason: `Kvarentina`,
  slotLog: `Log`,
  deleteSlot: `Обрисати слот`,
  deleteSlotLabel: `Molimo vas da navedete razlog brisanja`,
  errorDeleteSlot: `Не можете обрисати слот.`,
  errorDeleteInfoSlot: `Текући слот не може бити обрисан, уместо тога може бити скраћен.`,
  fieldRequired: `{{field}}: поље је обавезно`,
  from: `od `,
  until: `do `,
  issueResolveDeleteReason: `Pitanje je rešeno / slot nije potreban`,
  createdByMistakeDeleteReason: `Slot kreiran greškom`,
  guestRejectedMoveDeleteReason: `Gost odbio promenu sobe`,
  guestAcceptedMoveDeleteReason: `Gost prihvatio premještanje sobe`,
  deleteReasonIsRequired: `Razlog za brisanje je obavezan`,
  editSlot: `Izmeni slot`,
  earlyCheckinByHour: `ECI - čišćenje do {{hour}}:00`,
  paidEcis: `Plaćeni ECIs u {{hour}}:00`,
  invalidTimeFormat: `{{field}} format nije validan`,
};
