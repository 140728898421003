import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Badlüfter laut',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Lampe defekt',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Abfluss verstopft',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Duschabfluss verstopft',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Badezimmerfilter',
  [StandardizedDamageReport.SINK]: 'Waschbecken',
  [StandardizedDamageReport.TOILET]: 'Toilette',
  [StandardizedDamageReport.SHOWER]: 'Dusche',
  [StandardizedDamageReport.STOVE]: 'Herd',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Kühlschrank/Minibar',
  [StandardizedDamageReport.DOOR]: 'Tür',
  [StandardizedDamageReport.WINDOW]: 'Fenster',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'W-lan',
  [StandardizedDamageReport.LIGHTBULB]: 'Die Glühbirne',
  [StandardizedDamageReport.CHAIRS]: 'Stühle',
  [StandardizedDamageReport.HEATING]: 'Heizung',
  [StandardizedDamageReport.TV]: 'Fernseher',
  [StandardizedDamageReport.TABLES]: 'Tabellen',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'HSK',
  [Team.MAINTENANCE]: 'Maintenance',
  [Team.OPERATIONS]: 'Operations',
  [Team.ICT]: 'ICT',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Küche',
  [DamageReportArea.BED]: 'Bett',
  [DamageReportArea.BATHROOM]: 'Badezimmer',
  [DamageReportArea.SEATING_AREA]: 'Sitzbereich',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Wände/Decke/Boden',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Sauber',
  [DamageReportActionRequired.REPLACE]: 'Ersetzen',
  [DamageReportActionRequired.REPAIR]: 'Reparatur',
  [DamageReportActionRequired.CHECK]: 'Prüfen',
  [DamageReportActionRequired.OTHER]: 'Sonstiges',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Standard',
  [TaskPriority.HIGH]: 'Hoch',
};

const popupNotifications = {
  taskDeletionNotification: 'Aufgabe wurde erfolgreich gelöscht',
  lostAndFoundDeletionNotification: 'Lost & found wurde erfolgreich gelöscht',
  damageReportDeletionNotification: 'Schadensmeldung wurde erfolgreich gelöscht',
  taskCreationNotification: 'Aufgabe wurde erfolgreich erstellt',
  lostAndFoundCreationNotification: 'Lost & found wurde erfolgreich erstellt',
  damageReportCreationNotification: 'Schadensmeldung wurde erfolgreich erstellt',
  taskCompletionNotification: 'Aufgabe wurde erfolgreich beendert',
  lostAndFoundCompletionNotification: 'Lost & found wurde erfolgreich gelöst',
  damageReportCompletionNotification: 'Schadensmeldung wurde erfolgreich gelöst',
  taskUpdateNotification: 'Aufgabe wurde erfolgreich geändert',
  lostAndFoundUpdateNotification: 'Lost & found wurde erfolgreich geändert',
  damageReportUpdateNotification: 'Schadensmeldung wurde erfolgreich geändert',
  pleaseSelectRoomsNotification: 'Bitte wähle die Zimmer aus',
  noShowReportNotification: 'No-show erfolgreich gemeldet für Zimmer {{ number }}',
  checkInReportNotification: 'Check-in erfolgreich gemeldet für Zimmer {{ number }}',
  damageReportWorkingTimeScheduledNotification: 'Schadensbericht erfolgreich geplant für {{date}}',
  hskDelayNotification:
    'Erfolgreiche HSK-Verzögerung für Raum {{number}}. Die Check-in-Zeit wird auf {{time}} aktualisiert',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  resetAllFilters: 'Alle Filter zurücksetzen',
  moreFilters: 'Weitere Filter',
  reset: 'Zurücksetzen',
  allRoomsCleaned: `Alle Zimmer gereinigt`,
  cleaned: 'Sauber',
  dirty: 'Dreckig',
  readyToClean: 'Reinigung notwendig',
  stayoverReadyToClean: 'Bleibe - Ready to Clean',
  occupiedCleaningToday: 'Belegt - Heute zu reinigen',
  readyToInspect: 'Bereit zur Inspektion',
  stayoverInspection: 'Bleibe - Inspektion',
  vacant: 'Frei',
  occupied: 'Belegt',
  clean: 'Sauber',
  daily: 'Täglich',
  weekly: 'Wöchentlich',
  biweekly: 'Alle 2 Wochen',
  monthly: 'Monatlich',
  none: 'Kein',
  tasks: 'Aufgaben',
  notifications: 'Benachrichtigungen',
  more: 'Mehr',
  select: 'Auswählen',
  property: 'Haus',
  result: 'Ergebnis',
  results: 'Ergebnisse',
  properties: 'Häuser',
  rooms: 'Zimmer',
  team: 'Team',
  dueDate: 'Fälligkeitsdatum',
  date: 'Datum',
  close: 'Schließen',
  search: 'Suchen',
  login: 'Login',
  loading: 'Lädt...',
  anErrorHasOccurred: 'Ein Fehler',
  chooseDate: 'Wähle Datum',
  selectDate: 'Wähle Datum',
  selectADate: 'Wähle ein Datum',
  markAsClean: 'Als gereinigt markieren',
  markAsReadyToInspect: 'Bereit zur Inspektion',
  viewTasks: 'Aufgaben ansehen',
  eci: 'ECI',
  lco: 'LCO',
  roomLog: `Zimmerlog`,
  taskLog: `Aufgabenlog`,
  snacks: 'SNACKS',
  seeDetails: 'Details',
  assignedTasks: 'Zugewiesene Aufgaben',
  delete: 'Lösschen',
  deleteTask: 'Aufgabe löschen',
  deleteTaskConfirmationMessage: 'Bist du sicher, dass du diese Aufgabe löschen willst?',
  deleteTaskRepeatsConfirmationMessage:
    'Dies ist eine wiederkehrende Aufgabe. Du kannst nur diese oder alle Aufgaben löschen',
  deleteOnlyThisTask: 'Nur eine Aufgabe löschen',
  deleteRepeatingTask: 'Alle wiederkehrenden Aufgaben löschen',
  yes: 'Ja',
  no: 'Nein',
  pleaseWaitAFewSeconds: 'Bitte warte einen Moment',
  deleting: 'Es wird gelöscht',
  lostAndFound: 'Lost and found',
  current: 'Aktuell',
  archive: 'Archiv',
  edit: 'Bearbeiten',
  markAsResolved: 'Erledigt',
  markAsUnresolved: 'Nicht erledigt',
  add: 'Hinzufügen',
  area: 'Bereich',
  addTask: 'Aufgabe hinzufügen',
  seeTaskDetails: 'Aufgabe anzeigen',
  savePicturesAndStartCleaning: 'Speichern und mit der Reinigung beginnen',
  savePictures: 'Bilder speichern',
  editTask: 'Aufgabe ändern',
  takePhoto: 'Foto machen',
  addArea: 'Bereich hinzufügen',
  addAreaDescription: 'Füge einen Bereich hinzu',
  selectProperty: 'Haus auswählen',
  selectRooms: 'Zimmer auswählen',
  selectTeam: 'Team auswählen',
  selectDueDate: 'Datum auswählen',
  hskTeam: 'HSK Team',
  actionIsRequired: `Aktion erforderlich. Titel löschen und eine Aktion aus der Liste auswählen.`,
  noActionSelected: `Keine Aktion ausgewählt`,
  changeSearchAction: `Versuch mal, den Suchbegriff zu ändern`,
  noActionFound: `Keine Aktion gefunden`,
  selectActionHelp: `Nicht gefunden? Bitte kontaktiere das Operations-Team, damit es die Aktion anfordern kann.`,
  writeToSelectAction: 'Schreiben, um eine Aktion auszuwählen',
  save: 'Speichern',
  repetition: 'Wiederholung',
  noRepetition: 'Keine Wiederholung',
  writeTask: 'Aufgabe erstellen',
  repetitionTime: 'Häufigkeit',
  doesNotRepeat: 'Wiederholt sich nicht',
  day: 'Tag',
  week: 'Woche',
  month: 'Monat',
  repeatOn: 'Wiederholen am',
  repeatsEvery: 'Wiederholen jeden',
  description: 'Beschreibung',
  title: 'Titel',
  addTitle: 'Titel hinzufügen',
  titleIsRequired: 'Titel ist erforderlich',
  titleMaxLength: 'Der Titel sollte weniger als {{maxLength}} zeichen haben',
  teamIsRequired: 'Team erforderlich',
  openTasks: 'Offene Aufgaben',
  arrival: 'Ankunft',
  departure: 'Abreise',
  checkIn: 'Check In',
  times: 'Daten',
  guests: 'Gäste',
  name: 'Name',
  additionalInfo: 'Zusätzliche Infos',
  addLostAndFound: 'Lost and found melden',
  addDamageReport: 'Schaden melden',
  unitIsRequired: 'Zimmer erforderlich',
  addItem: 'Eintrag hinzufügen',
  editItem: 'Eintrag ändern',
  startCleaning: 'Reinigung - Starten',
  endCleaning: 'Reinigung - Beenden',
  endCleaningMessage: 'Es gibt 1 offene Aufgabe. Bist du dir sicher, dass du die Reinigung beenden möchtest?',
  endCleaningMessage_plural:
    'Es gibt {{count}} offene Aufgaben. Bist du dir sicher, dass du die Reinigung beenden möchtest?',
  cancelAndViewTasks: 'Abbrechen und Aufgaben ansehen',
  ignoreAndMarkAsReadyToInspect: 'Ja, bereit zur Inspektion',
  ignoreAndMarkAsCleaned: 'Ja, Zimmer ist gereinigt',
  damageReports: 'Schadensmeldungen',
  confirm: 'Bestätigen',
  selectRoomOrArea: 'Wähle ein Zimmer oder Bereich aus ',
  createNewOne: 'Neuen Bereich erstellen',
  today: 'Heute',
  tomorrow: 'Morgen',
  noTasksMessage: 'Es wurden keine Aufgaben hinzugefügt',
  cleaningStatus: 'Wird gereinigt',
  logOut: 'Log out',
  language: 'Sprache',
  selectLanguage: 'Sprache auswählen',
  isStandardized: 'Ist das eine Standardaufgabe',
  selectStandardizedTask: 'Standard Aufgabe auswählen',
  overdue: 'Überfällig',
  arrivals: 'Anreisen',
  memberArrivals: 'Member arrivals',
  departures: 'Abreisen',
  stayovers: 'Midstay Reinigung',
  occupancy: 'OTB Belegung',
  midstayCleaning: 'Bleibereinigung',
  roomsToSell: 'Zimmer zum Verkauf',
  editImages: 'Foto ändern',
  toClean: 'zu reinigen',
  toInspect: 'zu prüfen',
  noRoomsForCleaningToday: 'Keine Zimmer zu reinigen',
  noShow: 'No Show',
  noShowReportConfirmationMessage: 'Bitte bestätigen Sie, dass die Zimmernummer {{number}} No Show ist.',
  noShowReportQuestion: 'Ist der Gast gestern eingecheckt?',
  checkInReportConfirmationMessage: 'Bitte bestätige, dass der Gast in Zimmer {{ number }} eingecheckt ist',
  noResultsSearchMessage: 'Keine Ergebnisse entsprechen Ihren Suchkriterien',
  cancel: 'Abbrechen ',
  selectCleaner: 'Wählen Sie Reiniger',
  selectAll: 'Wählen Sie Alle',
  floor: 'Boden',
  notAssigned: 'Nicht zugeordnet',
  assignXRooms: 'Weisen Sie {{total}} Räume zu',
  assignRooms: 'Räume zuweisen',
  approvedLCOs: 'Zugelassene LCOs',
  cleaner: 'Reinigerin',
  roomsCleanForToday: 'Alle Zimmer sind für heute sauber! 🎉🎉',
  viewDamageReports: 'Schadensberichte anzeigen',
  noTasks: 'Keine Aufgaben',
  noDamageReports: 'Keine Schadensmeldungen',
  dailyCleaningReport: 'Täglicher Reinigungsbericht',
  stayover: 'Aufenthalt',
  roomMoveFrom: 'Zimmerumzug von {{value}}',
  roomMoveTo: 'Zimmerverlegung nach {{value}}',
  unassignAll: 'Alle Zuweisung aufheben',
  unassignRoomsConfirmationMessage: 'Möchten Sie die Zuweisung von {{total}} Räumen wirklich aufheben?',
  unassignRoomsTitle: 'Raumzuweisung aufheben',
  hideActions: 'Aktionen ausblenden',
  showActions: 'Aktionen anzeigen',
  noRoomAssigned: 'Kein Zimmer zugewiesen',
  disableActionTitleModal: 'Filter {{name}} zurücksetzen',
  disableActionDescriptionModal:
    'Um den {{currentFilter}}-Filter zu verwenden, setzen Sie bitte den {{resetFilter}}-Filter zurück.',
  action: 'Aktion',
  actionsSelected: '{{total}} Aktion(en) ausgewählt',
  actionRequired: 'Handlung erforderlich',
  priority: 'Priorität',
  lcoUntil: 'LCO bis',
  created: 'Erstellt',
  startNow: 'Jetzt anfangen',
  scheduleTime: 'Planmäßige Zeit',
  viewInfo: 'Informationen anzeigen',
  reminder: 'Erinnerung',
  reminderTaskNotificationText: 'Ihre geplante Aufgabe ist heute fällig',
  inProgress: 'In Bearbeitung',
  scheduled: 'Geplant',
  moveMidstayCleaning: 'Stayover-Reinigung verschieben?',
  moveMidstayTitle: 'Mitte des Aufenthalts bewegen',
  moveMidstayDescription: 'Möchten Sie die Zwischenreinigung wirklich auf das {{date}} verschieben?',
  moveMidstayNotPossible:
    'Verzeihung! Die Zwischenreinigung für dieses Zimmer kann nicht auf einen anderen Tag verschoben werden!',
  moveMidstayNotification: 'Die Stayover-Reinigung wurde erfolgreich auf {{date}} verschoben.',
  pleaseContactOps: 'Bitte wenden Sie sich an das Operations-Team',
  midCleanMovedTo: 'Restreinigung verschoben nach: {{value}}',
  optional: 'Optional',
  hskDelay: 'HSK-Verzögerung',
  hskDelays: 'HSK verzögert',
  hskDelayMessage: 'Check-in-Zeit für Zimmer {{number}} aktualisieren',
  notStarted: 'Nicht angefangen',
  resolved: 'Behoben',
  noDamageReportsToday: 'Keine Schadensmeldungen für heute',
  yourTask: 'Deine Aufgabe',
  yourTaskGX: 'Ihre Aufgabe von GX',
  damageReportedByGX: 'Von GX gemeldeter Schaden',
  stay: 'Aufenthaltsdaten',
  checkOut: 'Abreisezeit',
  noPermission: 'Du hast keine Berechtigung, bitte kontaktiere den Support.',
  approvedCheckoutTime: 'Genehmigte Check-Out-Zeit',
  requestedCheckoutTime: 'Angefragte Check-Out-Zeit',
  checkinTime: 'Check-In-Zeit',
  commentForHousekeeping: 'Kommentar für HSK',
  numberOfApprovedLCOs: 'Anzahl der genehmigten LCOs',
  notSet: 'Nicht festgelegt',
  vip: 'VIP',
  extraService: 'Extra-Service',
  reservationMissing: 'Die Reservierung wurde wahrscheinlich storniert, wechsel stattdessen zum General-Tab.',
  confirmFor: 'Für {{date}} bestätigen',
  confirmAnotherDay: 'Anderen Tag bestätigen',
  taskConfirmed: 'Du hast die Aufgabe für {{date}} bestätigt.',
  confirmYourTask: 'Bestätige deine Aufgabe',
  confirmYourTaskGX: 'Bestätige deine Aufgabe von GX',
  confirmHighPriorityTaskMessage:
    'Möchtest du das Datum wirklich auf heute setzen? Es ist wahrscheinlich, dass die Aufgabe heute nicht bestätigt und erledigt wird.',
  setTomorrow: 'Auf morgen setzen',
  keepToday: 'Heute behalten',
  createTask: 'Aufgabe erstellen',
  confirmationNeeded: 'Bestätige deine Aufgabe von GX',
  pictureTooBlurry: `Das Bild ist zu unscharf. Bitte mach ein klares Foto und lade es hoch.`,
  tryAgain: `Nochmal versuchen`,
  addDescription: `Beschreibung hinzufügen`,
  togglePushNotificationsLabel: `Push-Benachrichtigungen für iOS`,
  roomRack: `Zimmerplan`,
  newSlot: `Neuer Wartungsslot`,
  searchByRoomNumber: `Zimmernummer`,
  reservation: `Reservierung`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Slottyp`,
  selectType: `Typ auswählen`,
  reason: `Grund`,
  selectReason: `Grund auswählen`,
  selectDateAndTime: `Datum und Uhrzeit`,
  startDate: `Startdatum`,
  startTime: `Startzeit`,
  endDate: `Enddatum`,
  endTime: `Endzeit`,
  room: `Zimmer`,
  selectRoom: `Raum auswählen`,
  cleanAfterBlock: `Reinigung erforderlich nach dem Slot`,
  addSlot: `Slot hinzufügen `,
  updateSlot: `Slot aktualisieren`,
  errorStartDatePastSlotForm: `Startdatum kann nicht in der Vergangenheit liegen`,
  errorEndDatePastSlotForm: `Enddatum kann nicht vor dem Startdatum liegen`,
  errorEndTimePastSlotForm: `Endzeit kann nicht vor Startzeit liegen`,
  errorMinDurationSlotForm: `Die minimale Slot-Dauer beträgt 30 Minuten`,
  errorOverbookedSlotForm: `Der Zeitblock könnte zu Überbuchung an ausgewählten Tag(en) führen. Erstelle ihn nur, wenn es wirklich notwendig ist, ansonsten wähle einen anderen Zeitraum aus.`,
  outOfOrderInfo: `OOO - Out of Order. Soll verwendet werden, wenn ein Zimmer nicht funktionsfähig ist und von einem Gast nicht genutzt werden kann.`,
  outOfServiceInfo: `OOS - Out of Service. Ermöglicht den Verkauf des Zimmers. Ein als Out of Service markiertes Zimmer ist voll funktionsfähig und hat nur geringfügige kosmetische Mängel.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Wird vom Launch-Team für langfristige Wartungspläne verwendet, wie die Renovierung eines ganzen Stockwerks.`,
  maintenanceReason: `Instandhaltung`,
  cleaningIssueReason: `Reinigungsproblem`,
  roomMoveBlockReason: `Zimmer- oder Hotelwechsel`,
  bedBugsReason: `Wanzenbett`,
  smokingReason: `Rauchen`,
  vipGuestReason: `VIP-Gast`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: strategisch`,
  rampUpRnrReason: `Ramp-up: Zimmer nicht bereit`,
  hoReason: `HSK-Optimierung`,
  othersReason: `Andere Gründe`,
  createdInApaleo: `Erstellt in Apaleo`,
  reasonNotApaleo: `\`Erstellt in Apaleo\` ist kein gültiger Grund. Bitte wählen Sie einen anderen.`,
  quarantineReason: `Quarantäne`,
  slotLog: `Log`,
  deleteSlot: `Den Slot löschen`,
  deleteSlotLabel: `Löschgrund angeben`,
  errorDeleteSlot: `Der Slot kann nicht gelöscht werden.`,
  errorDeleteInfoSlot: `Ein laufender Slot kann nicht gelöscht werden, stattdessen kann er verkürzt werden.`,
  fieldRequired: `{{field}}: Feld ist erforderlich`,
  from: `vom `,
  until: `bis zum `,
  issueResolveDeleteReason: `Problem wurde behoben / Slot nicht benötigt`,
  createdByMistakeDeleteReason: `Slot durch Fehler erstellt`,
  guestRejectedMoveDeleteReason: `Gast lehnte Zimmerwechsel ab`,
  guestAcceptedMoveDeleteReason: `Gast akzeptierte Zimmerwechsel`,
  deleteReasonIsRequired: `Der Löschgrund ist erforderlich`,
  editSlot: `Slot bearbeiten`,
  earlyCheckinByHour: `ECI - Reinigung bis {{hour}}:00`,
  paidEcis: `Bezahlte ECIs um {{hour}}:00`,
  invalidTimeFormat: `Das {{field}}-Format ist ungültig`,
};
