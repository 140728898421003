import { useMemo } from 'react';
import { Listbox, Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { InformationCircleIcon } from '@heroicons/react/outline';

import { allReasonsSlotIncludingNotValid } from '../constants';
import { SVGIconType } from '@typings/types';

type Option = {
  label: string;
  value: string;
  helpText?: string;
  icon?: SVGIconType;
};

interface SelectorProps {
  label?: string;
  ContentInfoText?: () => JSX.Element;
  placeholder?: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  optionsClassName?: string;
  isDisabled?: boolean;
  error?: string;
}

export const Selector = ({
  label,
  ContentInfoText,
  placeholder,
  options,
  value,
  isDisabled = false,
  optionsClassName,
  onChange,
  error,
}: SelectorProps) => {
  const ReasonSlotIcon = useMemo(() => {
    if (!value) return null;
    return allReasonsSlotIncludingNotValid.find((reason) => reason.value === value)?.icon;
  }, [value]);

  return (
    <div>
      {ContentInfoText ? (
        <Popover className="relative">
          <Popover.Button className="font-sans text-th-brown-300 font-semibold text-xs leading-4 flex justify-start items-center mb-1">
            <span className="mr-1">{label}</span>
            <InformationCircleIcon width={16} height={16} />
          </Popover.Button>
          <Popover.Panel className="absolute top-4 z-10 bg-white shadow-md p-2 rounded-lg">
            <ContentInfoText />
          </Popover.Panel>
        </Popover>
      ) : (
        label && <span className="font-sans text-th-brown-300 font-semibold text-xs leading-4">{label}</span>
      )}
      <Listbox value={value} onChange={onChange} disabled={isDisabled}>
        <div className="relative">
          <Listbox.Button
            className={`border ${error ? 'border-th-red-300' : 'border-th-brown-100'} ${
              isDisabled && 'border-th-brown-100 bg-th-gray-250 cursor-default'
            } rounded-[4px] p-2 flex justify-between items-center w-full`}
          >
            <div className="flex justify-start items-center truncate">
              {ReasonSlotIcon && (
                <div className="mr-2">
                  <ReasonSlotIcon width={20} height={20} />
                </div>
              )}
              <span className="block truncate text-th-brown-300">
                {value ? options.find((option) => option.value === value)?.label : placeholder ?? ''}
              </span>
            </div>
            {!isDisabled && <ChevronDownIcon className="w-5 h-5" />}
          </Listbox.Button>
          <Listbox.Options
            className={`absolute z-20 w-full py-1 max-h-96 overflow-auto text-base bg-white 
                  rounded-md shadow-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
                    optionsClassName ?? ''
                  }`}
          >
            {options.map((option) => {
              const isOptionChecked = value === option.value;
              return (
                <Listbox.Option
                  key={option.value}
                  value={option.value}
                  className={`cursor-pointer relative p-4 hover:bg-th-brown-50 focus:bg-th-light-green ${
                    isOptionChecked && 'bg-th-light-green'
                  }`}
                >
                  <div className="flex justify-start items-center gap-x-2 text-th-brown font-medium text-base">
                    {option.icon && <option.icon width={24} height={24} />}
                    <span className={`block`}>{option.label}</span>
                  </div>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </div>
      </Listbox>
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};
